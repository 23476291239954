import React, { ReactNode } from 'react'

import { IconNames } from 'components_new/atoms/Icon'

import {
  AttributeOption,
  ParsedSegmentPath,
  WidgetObject
} from 'types/GlobalWidget'
import { DETAILS_DISABLE_TEXT } from 'utils/constants'

import { OptionProps } from 'components_new/molecules/OptionsMenu'
import SegmentMenuSubContent from './SegmentMenuSubContent'

/**
 * Get all available segment options based on current
 * hierarchy level of widget, minimum 3.
 * Allows same level or lower.
 * @param attributeOptionId - Current segment attribute option id.
 * @param segmentOptions - All available segmentation options for widget.
 * @returns - Available segmentations for "Dela upp efter".
 */
const getAvailableSegmentOptions = (
  customSegmentBy: string,
  segmentOptions: AttributeOption[]
): AttributeOption[] => {
  const selectedOption = segmentOptions.find(
    (option) => option.id === customSegmentBy
  )

  if (!selectedOption) {
    return []
  }

  return segmentOptions.filter(
    (option) => option.allow_custom_segment_by && option.id !== customSegmentBy
  )
}

const getSegmentOptionsMenuContent = (
  widget: WidgetObject,
  segments: ParsedSegmentPath[],
  setCustomSegmentBy?:
    | ((attributeOptionId: string, segments: ParsedSegmentPath[]) => void)
    | null
): ReactNode => {
  const clickedSegment = segments[segments.length - 1]
  const segmentAttributeOptionId = clickedSegment.attribute_option_id as string

  const segmentOptions = getAvailableSegmentOptions(
    segmentAttributeOptionId,
    widget.settings.segment_by.options
  )

  if (segmentOptions.length === 0 || !setCustomSegmentBy) {
    return null
  }

  return (
    <SegmentMenuSubContent
      segmentOptions={segmentOptions}
      setCustomSegmentBy={(attributeOptionId) => {
        setCustomSegmentBy(attributeOptionId, segments)
      }}
    />
  )
}

export const getOptions = (
  widget: WidgetObject,
  segments: ParsedSegmentPath[],
  setFilters: (segments: ParsedSegmentPath[]) => void,
  setDetailsDialog?: ((segments: ParsedSegmentPath[]) => void) | null,
  customSegmentBy?: string | null,
  setCustomSegmentBy?:
    | ((attributeOptionId: string, segments: ParsedSegmentPath[]) => void)
    | null,
  onClickExternalLink?: ((link: string | null) => void) | null,
  link: string | null = null,
  isPublic?: boolean
): OptionProps[] => {
  const segmentContent =
    customSegmentBy !== undefined
      ? getSegmentOptionsMenuContent(widget, segments, setCustomSegmentBy)
      : null

  const getCustomSegmentDisabledTitle = () => {
    if (isPublic) {
      return 'Logga in i Homepal för att få tillgång till den här funktionaliteten'
    } else if (!segmentContent) {
      return 'Det går inte att dela upp det här segmentet ytterligare'
    } else {
      return undefined
    }
  }

  const getDetailsDisabledTitle = () => {
    if (isPublic) {
      return 'Logga in i Homepal för att få tillgång till den här funktionaliteten'
    } else if (!Boolean(setDetailsDialog)) {
      return DETAILS_DISABLE_TEXT
    } else {
      return undefined
    }
  }

  return [
    {
      iconName: 'FilterAltOutlined' as IconNames,
      onClick: () => setFilters(segments),
      title: 'Lägg till i filter'
    },
    {
      disabled: !Boolean(setDetailsDialog),
      iconName: 'TableRowsOutlined' as IconNames,
      infoTip: getDetailsDisabledTitle(),
      onClick: setDetailsDialog ? () => setDetailsDialog(segments) : () => {},
      title: 'Visa innehåll'
    },
    // if customSegmentBy is undefined then dont show option
    // e.g. for line charts
    ...(customSegmentBy !== undefined
      ? [
          {
            disabled: !segmentContent,
            iconName: 'AutoFixHighOutlined' as IconNames,
            infoTip: getCustomSegmentDisabledTitle(),
            onClick: () => {},
            title: 'Dela upp efter...',
            content: segmentContent
          }
        ]
      : []),
    ...(onClickExternalLink
      ? [
          {
            disabled: !link,
            iconName: 'LinkOutlined' as IconNames,
            onClick: () => onClickExternalLink(link),
            title: link
              ? 'Öppna i källsystem'
              : 'Saknar koppling till källsystem'
          }
        ]
      : [])
  ]
}
