import React, { ElementType, ReactNode, useState } from 'react'

import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'
import { NavigationMenuStore } from 'redux/reducers/NavigationMenu'
import { ApplicationState } from 'redux/Stores/types'

import * as NavigationMenuStoreActions from 'redux/actions/NavigationMenu'

import {
  Container as MUIContainer,
  ThemeProvider,
  useTheme
} from '@mui/material'

import Box from 'components_new/atoms/Box'
import { IconNames } from 'components_new/atoms/Icon'

import FixedSideButton from 'components_new/molecules/FixedSideButton'

import BannerBar from 'components_new/organisms/BannerBar'
import NavBar from 'components_new/organisms/NavBar'
import TopBar from 'components_new/organisms/TopBar'

import { getTheme } from 'themes'

interface PageContainerProps {
  bgcolor?: string
  children?: ReactNode
  component?: ElementType<any>
  leftSidebar?: ReactNode
  leftSidebarSize?: 'small' | 'medium' | 'large'
  navContent?: ReactNode
  navFooter?: ReactNode
  navHeader?: ReactNode
  navLock?: boolean
  navMenu?: ReactNode
  NavigationMenuStore?: NavigationMenuStore
  noGutter?: boolean
  rightSidebar?: ReactNode
  rightSidebarBadge?: boolean
  rightSidebarIconName?: IconNames
  rightSidebarTooltip?: string
  topBar?: ReactNode
  topBarActions?: ReactNode
  topBarNoGutter?: boolean
}

/**
 * The PageContainer is used as a wrapper for our pages,
 * i.e. the outmost element in a template.
 *
 * It is used for (in a simple way) keeping a consistent
 * layout.
 *
 * To do: Replace MUIContainer with our own atom/Container
 * when it has been updated.
 */

const PageContainer = React.forwardRef((props: ComponentProps, ref: any) => {
  const {
    bgcolor,
    children,
    leftSidebar,
    leftSidebarSize,
    navContent,
    navFooter,
    navHeader,
    NavigationMenuStore,
    navLock,
    navMenu,
    noGutter,
    rightSidebar,
    rightSidebarBadge,
    rightSidebarTooltip,
    rightSidebarIconName,
    topBar,
    topBarActions,
    topBarNoGutter,
    ...rest
  } = props

  const [collapsed, setCollapsed] = useState<boolean>(false)
  const theme = useTheme()

  const navOpen = NavigationMenuStore?.open

  return (
    <>
      {/*-- banner bar --*/}
      <BannerBar />

      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          bgcolor: bgcolor ?? 'background.default',
          minHeight: 0
        }}
      >
        {/*-- top bar --*/}
        <TopBar
          actions={topBarActions}
          navLock={navLock}
          noGutter={topBarNoGutter}
          title={navHeader}
        >
          {topBar}
        </TopBar>

        <MUIContainer
          {...rest}
          disableGutters={true}
          maxWidth={false}
          ref={ref}
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'hidden',
            bgcolor: bgcolor ?? 'background.default',
            minHeight: 0
          }}
        >
          {navContent || navFooter || navHeader || navMenu ? (
            <ThemeProvider theme={getTheme('light')}>
              <Box
                sx={{
                  position: 'relative',
                  height: '100%',
                  minWidth: navOpen || navLock ? 260 : 56,
                  maxWidth: navOpen || navLock ? 500 : 56,
                  flexBasis: navOpen || navLock ? '8%' : undefined,
                  transition: 'all 0.1s',
                  transitionTimingFunction: theme.transitions.easing.easeInOut,
                  bgcolor: bgcolor ?? 'background.default'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    overflow: 'auto'
                  }}
                >
                  {/*-- nav bar --*/}
                  <NavBar
                    content={navContent}
                    locked={navLock}
                    footer={navFooter}
                    menu={navMenu}
                  />
                </Box>
              </Box>
            </ThemeProvider>
          ) : null}

          {/*-- left side bar --*/}
          {leftSidebar ? (
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                minWidth: leftSidebarSize === 'small' ? 220 : 280,
                maxWidth: 500,
                flexBasis: leftSidebarSize === 'small' ? '15%' : '20%'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  overflowY: 'auto'
                }}
              >
                {leftSidebar}
              </Box>
            </Box>
          ) : null}
          <Box
            sx={{
              flex: '1 1 auto',
              minWidth: 0,
              height: '100%',
              position: 'relative'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                overflow: 'auto',
                pb: noGutter ? undefined : 2,
                pr: noGutter ? undefined : 2
              }}
            >
              {children}

              {/*-- right side bar --*/}
              {rightSidebar ? (
                <FixedSideButton
                  badge={rightSidebarBadge}
                  iconName={
                    rightSidebarIconName
                      ? rightSidebarIconName
                      : collapsed
                        ? 'ChevronLeft'
                        : 'ChevronRight'
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  tooltip={`${
                    collapsed ? 'Öppna' : 'Stäng'
                  } ${rightSidebarTooltip?.toLowerCase()}`}
                />
              ) : null}
            </Box>
          </Box>

          {/*-- right side bar --*/}
          {rightSidebar ? (
            <Box
              sx={{
                bgcolor: 'background.default',
                border: '1px solid',
                borderColor: 'divider',
                height: '100%',
                minWidth: collapsed ? 0 : 260,
                maxWidth: collapsed ? 0 : 500,
                flexBasis: collapsed ? undefined : '8%',
                position: 'relative',
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.leavingScreen
                })
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  overflowY: 'auto'
                }}
              >
                {rightSidebar}
              </Box>
            </Box>
          ) : null}
        </MUIContainer>
      </Box>
    </>
  )
})

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...NavigationMenuStoreActions
    },
    dispatch
  )
}

interface ComponentDispatchProps {
  toggleNavigationOpen(): (dispatch: Dispatch) => void
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  ComponentDispatchProps &
  PageContainerProps

export default connector(PageContainer)
