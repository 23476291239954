import React from 'react'

import { DashboardReducerType } from 'redux/reducers/Dashboards'

export const isValidTitle = (title: string) => {
  return !/test|kopia|namnlös/i.test(title)
}

export const getChecklistItems = (
  dashboardId: string,
  KPIDashboardStore: DashboardReducerType
) => {
  const dashboard = KPIDashboardStore.data[dashboardId]
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

  return [
    {
      title: 'Dashboarden har en riktig titel',
      body: (
        <>
          Se till att din dashboard inte heter &quot;Namnlös&quot; eller
          innehåller ord så som &quot;Test&quot; och &quot;Kopia&quot;.
        </>
      ),
      value: isValidTitle(group.title)
    },
    {
      title: 'Alla flikar har riktiga titlar',
      body: (
        <>
          Se till att dina flikar inte heter &quot;Namnlös&quot; eller
          innehåller ord så som &quot;Test&quot; och &quot;Kopia&quot;.
        </>
      ),
      value: group.dashboards.every((dashboardId) =>
        isValidTitle(KPIDashboardStore.data[dashboardId].title)
      )
    },
    {
      title: 'Max 3 flikar',
      body: (
        <>
          Vi rekommenderar att undvika flikar{' '}
          <u>om målet är att öka användaraktiviteten</u>. Skapa istället
          relevanta användarfilter för att ge varje sin specifika vy. Om du ändå
          vill använda flikar, försök att hålla antalet till 2-3 stycken.
        </>
      ),
      value: group.dashboards.length <= 3
    },
    {
      title: 'Max 10 widgets',
      body: (
        <>
          Om du har många widgets har du troligen inte fokuserat på det mest
          väsentliga. Det kan då bli svårt för användaren att veta vad de ska
          fokusera på.
        </>
      ),
      value: group.dashboards.every(
        (dashboardId) =>
          Object.keys(KPIDashboardStore.data[dashboardId].widgets).length <= 10
      )
    }
  ]
}
