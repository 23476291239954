import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import {
  KpiOptionObject,
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import { QueryParams, parseQueryParam } from '../Widgets'
import { IUnderlyingDataResponse } from 'types/GlobalWidget'
import { getWithRedirect } from 'utils/axios'

export interface IUnderlyingQueryParams extends QueryParams {
  entity_columns?: string[]
}

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/kpi-options`

function parseUnderlyingQueryParams(
  queryParams: IUnderlyingQueryParams
): string {
  let parsedQueryParams = parseQueryParam(queryParams)

  if (queryParams.entity_columns) {
    parsedQueryParams += `${
      parsedQueryParams ? '&' : '?'
    }entity_columns=${queryParams.entity_columns
      .map((column) => encodeURIComponent(column))
      .join(',')}`
  }

  return parsedQueryParams
}

export async function create(body: KpiOptionPostAttributes) {
  return axios.post<{ data: KpiOptionObject }>(
    URL,
    body,
    parseAuthenticationHeader()
  )
}

export async function update(id: string, body: KpiOptionPatchAttributes) {
  return axios.patch<{ data: KpiOptionObject[] }>(
    `${URL}/${id}`,
    body,
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}

export async function getUnderlyingPosts(
  id: string,
  queryParams: IUnderlyingQueryParams
) {
  const parsedQueryParams = parseUnderlyingQueryParams(queryParams)
  const url = `${URL}/details/${id}${parsedQueryParams}`

  return getWithRedirect<IUnderlyingDataResponse>(url)
}
