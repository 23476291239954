import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

import { PatchCompanyBody } from 'types/GlobalCompany'
import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/companies`

export async function getAll() {
  return getWithRedirect(URL)
}

export async function update(id: string, body: PatchCompanyBody) {
  return Axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}
