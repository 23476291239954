import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import {
  DashboardStatus,
  LayoutItem,
  WhoCanView
} from 'redux/reducers/Dashboards'
import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/dashboards`

export interface DashboardPostBody {
  data: {
    template_id?: string
    dashboard_id?: string
    dashboard_group_id?: string
    folder_id?: string | null
  }
}
export interface DashboardPatchBody {
  data: {
    api_access?: boolean
    all_company_groups_has_access?: boolean
    is_public?: boolean
    permission_type?: WhoCanView
    status?: DashboardStatus
    subtitle?: string
    title?: string
  }
}

export type LayoutUpdate = {
  width: number
  height: number
  x: number
  y: number
  id: string
}

export interface WidgetLayoutPatchBody {
  data: {
    layout: LayoutUpdate[]
  }
}

export interface Permission {
  company_group_id?: string
  account_id?: string
  new_user_email?: string
  message?: string
}

export interface InviteUserBody {
  data: Permission
}

export async function getAll() {
  return getWithRedirect(URL)
}

export async function getOne(id: string) {
  return getWithRedirect(`${URL}/${id}`)
}

export async function getOnePublic(id: string) {
  return getWithRedirect(`${BASE_URL}v1/public/dashboards/${id}`, {})
}

export async function getOneApiAccess(id: string) {
  return getWithRedirect(`${BASE_URL}v1/api-access/dashboards/${id}`)
}

export async function create(body: DashboardPostBody) {
  return axios.post(URL, body, parseAuthenticationHeader())
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}

export async function update(id: string, body: DashboardPatchBody) {
  return axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function updateLayout(id: string, body: WidgetLayoutPatchBody) {
  return axios.put<{ data: LayoutItem | null }>(
    `${URL}/${id}/update-layout`,
    body,
    parseAuthenticationHeader()
  )
}

export async function inviteToDashboard(id: string, body: InviteUserBody) {
  return axios.post(`${URL}/${id}/invite`, body, parseAuthenticationHeader())
}

export async function revokeFromDashboard(
  id: string,
  accountId?: string,
  companyGroupId?: string
) {
  return axios.post(
    `${URL}/${id}/revoke`,
    { data: { account_id: accountId, company_group_id: companyGroupId } },
    parseAuthenticationHeader()
  )
}

export async function putThumbnail(id: string, thumbnail: Blob) {
  const formData = new FormData()

  formData.append('thumbnail', thumbnail)

  return axios.put(
    `${URL}/${id}/thumbnail`,
    formData,
    parseAuthenticationHeader()
  )
}
