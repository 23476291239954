import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/dashboard-examples`

export async function getDashboardExamples() {
  return getWithRedirect(URL)
}

export async function addVisit(body: { data: { dashboard_id: string } }) {
  return Axios.post(`${URL}/add-visit`, body, parseAuthenticationHeader())
}

export async function copy(
  id: string,
  body: { data: { folderId: null | string; title: string } }
) {
  return Axios.post(`${URL}/${id}/copy`, body, parseAuthenticationHeader())
}
