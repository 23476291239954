import React, { useEffect, useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'

import SidebarDialog from 'components_new/molecules/SidebarDialog'
import TabSwitch from 'components_new/molecules/TabSwitch'

import Checklist from './Checklist'
import DashboardView from './DashboardView'
import Metrics from './Metrics'
import UsabilityView from './UsabilityView'
import UsersView from './UsersView'

import MetricsLoading from './Metrics/loading'
import DashboardViewLoading from './DashboardView/loading'
import UsersViewLoading from './UsersView/loading'

import {
  TDashboardActivityPeriod,
  translateDashboardActivityPeriod
} from './utils'
import { TDashboardGroupActivity } from 'types/GlobalDashboardGroup'
import { getActivities } from 'redux/api/DashboardGroups'

interface ActivityDialogProps {
  dashboardGroupId: string
  onClose: () => void
  open: boolean
}

const ActivityDialog = (props: ActivityDialogProps) => {
  const { dashboardGroupId, onClose, open } = props

  const [data, setData] = useState<TDashboardGroupActivity | null>(null)
  const [tab, setTab] = useState<'views' | 'users'>('views')
  const [showUsability, setShowUsability] = useState<boolean>(false)
  const [period, setPeriod] = useState<TDashboardActivityPeriod>('SINCE_START')

  useEffect(() => {
    if (open) {
      getActivities(dashboardGroupId)
        .then((response) => setData(response.data.data))
        .catch(() => {})
    }
  }, [open])

  function handleClose() {
    onClose()

    setData(null)
    setTab('views')
    setShowUsability(false)
    setPeriod('SINCE_START')
  }

  return (
    <SidebarDialog
      actions={
        <Button variant="text" onClick={handleClose}>
          Stäng
        </Button>
      }
      disableOverflow={!showUsability}
      fullHeight={true}
      fullWidth={true}
      leftSidebar={
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {data ? (
            <Metrics
              dashboardGroupId={dashboardGroupId}
              data={data}
              period={period}
            />
          ) : (
            <MetricsLoading />
          )}
        </Box>
      }
      maxWidth={false}
      rightSidebar={
        showUsability ? undefined : (
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Checklist onShowMore={() => setShowUsability(true)} />
          </Box>
        )
      }
      noGutter={true}
      onClose={handleClose}
      open={open}
      title={'Aktivitetsöversikt'}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: 'background.paper',
          p: 2
        }}
      >
        {showUsability ? (
          <Button
            onClick={() => setShowUsability(false)}
            startIcon={<Icon name="ArrowBackOutlined" />}
            variant="text"
          >
            Tillbaka
          </Button>
        ) : (
          <>
            <TabSwitch
              onChange={(value) => setTab(value)}
              options={[
                { label: 'Visningar', value: 'views' },
                { label: 'Användare', value: 'users' }
              ]}
              value={tab}
            />
            <TextField
              label={'Tidsperiod'}
              onChange={(event) =>
                setPeriod(event.target.value as TDashboardActivityPeriod)
              }
              select={true}
              size="small"
              sx={{ width: 200 }}
              value={period}
            >
              <MenuItem value="THIS_WEEK">
                {translateDashboardActivityPeriod['THIS_WEEK']}
              </MenuItem>
              <MenuItem value="THIS_MONTH">
                {translateDashboardActivityPeriod['THIS_MONTH']}
              </MenuItem>
              <MenuItem value="THIS_YEAR">
                {translateDashboardActivityPeriod['THIS_YEAR']}
              </MenuItem>
              <MenuItem value="SINCE_START">
                {translateDashboardActivityPeriod['SINCE_START']}
              </MenuItem>
            </TextField>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 2,
          p: 2,
          pt: 0,
          overflow: showUsability ? undefined : 'hidden'
        }}
      >
        {showUsability ? (
          <UsabilityView />
        ) : (
          <>
            {tab === 'views' ? (
              <>
                {data ? (
                  <DashboardView data={data} period={period} />
                ) : (
                  <DashboardViewLoading />
                )}
              </>
            ) : (
              <>
                {data ? (
                  <UsersView data={data} period={period} />
                ) : (
                  <UsersViewLoading />
                )}
              </>
            )}
          </>
        )}
      </Box>
    </SidebarDialog>
  )
}

export default ActivityDialog
