import React, { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { cloneDeep } from 'lodash'

import { useMediaQuery, useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import ActivityDialog from '../dialogs/ActivityDialog'
import DashboardTabs from 'components_new/organisms/DashboardTabs'
import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'
import EditTitleDialog from 'components_new/organisms/dialogs/EditTitleDialog'
import ExportDialog from 'components_new/organisms/dialogs/ExportDialog'
import PageTitle from 'components_new/organisms/PageTitle'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as AccountActions from 'redux/actions/Accounts'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFavoriteActions from 'redux/actions/DashboardFavorites'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as WidgetActions from 'redux/actions/Widgets'

import { AccountRole } from 'types/GlobalUser'

import { getAvailableFolders, getAvailableGroups } from 'utils/functions'

import Loading from './loading'
import EditDashboardMenu from '../EditDashboardMenu'
import { DashboardStatus } from 'redux/reducers/Dashboards'

interface DashboardHeaderProps {
  gridRef: any | null
  editMode: boolean
  handleToggleEditMode: () => void
}

const DashboardHeader = (props: ComponentProps) => {
  const {
    gridRef,
    editMode,
    handleToggleEditMode,
    // redux stores:
    AuthStore,
    DashboardFolderStore,
    KPIDashboardStore,
    // redux actions:
    tryCreateDashboard,
    tryDeleteDashboard,
    tryPutDashboardFavorite,
    tryUpdateDashboard,
    tryUpdateDashboardGroup,
    tryUpdateDashboardOrder
  } = props
  const [editMenuAnchorEl, setEditMenuAnchorEl] =
    useState<HTMLAnchorElement | null>(null)

  const handleOpenEditMenu = (event: any) => {
    setEditMenuAnchorEl(event.currentTarget)
  }

  const handleCloseEditMenu = () => {
    setEditMenuAnchorEl(null)
  }

  const history = useHistory()

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)
  const folders = getAvailableFolders(
    availableGroups,
    DashboardFolderStore.data,
    AuthStore.user
  )

  const selectedFolder = folders.find(
    (folder) => folder.id === group?.folder_id
  )

  const groupDashboards = useMemo(
    () =>
      KPIDashboardStore.groups[dashboard?.dashboard_group_id]?.dashboards.map(
        (id: string) => ({
          id,
          title: KPIDashboardStore.data[id].title,
          hasAccess: KPIDashboardStore.data[id].has_access
        })
      ),
    [KPIDashboardStore.groups, KPIDashboardStore.data]
  )

  const user = AuthStore.user
  const ownerId = group?.owner

  const isAdmin = user?.role === AccountRole.ADMIN
  const isHomepal = user?.is_homepal_user
  const isOwner = ownerId === user?.id

  const editable = isAdmin || isHomepal || isOwner

  type TDialogType =
    | 'dashboard_activity'
    | 'delete_dashboard'
    | 'edit_dashboard_title'
    | 'edit_template'
    | 'export_dashboard'
    | 'move_group'

  const [dialog, setDialog] = useState<{
    type: TDialogType
    open: boolean
    id: string
  }>({
    type: 'delete_dashboard',
    open: false,
    id: ''
  })

  const handleOpenDialog = (type: TDialogType, id?: string) => {
    setDialog({
      type: type,
      open: true,
      id: id ?? ''
    })
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  // loading
  if (
    !Boolean(AuthStore.user) ||
    !DashboardFolderStore.fetched ||
    !KPIDashboardStore.fetched ||
    !group
  ) {
    return <Loading />
  }

  // success
  return (
    <>
      <Box sx={{ mr: 0.5, flex: '0 1 auto', minWidth: 96 }}>
        <PageTitle
          editable={editable}
          folder={selectedFolder}
          onSubmit={(title: string) => {
            tryUpdateDashboardGroup(group.id, { data: { title } })
          }}
          title={group.title}
        />
      </Box>
      <Box sx={{ flex: '1 1 auto', minWidth: lgUp ? 272 : undefined }}>
        {editable || groupDashboards.length > 1 ? (
          <DashboardTabs
            dashboard={dashboard.id}
            dashboards={groupDashboards}
            editable={editable}
            onAddTab={() => {
              tryCreateDashboard(
                {
                  data: {
                    dashboard_group_id: group.id
                  }
                },
                (id) => history.push(`/dashboards/${id}?new_dashboard=true`)
              )
            }}
            onDelete={(id: string) => handleOpenDialog('delete_dashboard', id)}
            onDuplicate={(id: string) => {
              tryCreateDashboard(
                {
                  data: {
                    dashboard_id: id
                  }
                },
                (createdId) => history.push(`/dashboards/${createdId}`)
              )
            }}
            onEditTitle={(id: string) =>
              handleOpenDialog('edit_dashboard_title', id)
            }
            onMove={(id: string, dir: number) => {
              const currentIndex = group.dashboards.indexOf(id)
              const newOrder = cloneDeep(group.dashboards)

              newOrder.splice(currentIndex, 1)
              newOrder.splice(currentIndex + dir, 0, id)

              tryUpdateDashboardOrder(group.id, { data: newOrder })
            }}
          />
        ) : null}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flex: '0 1 auto' }}>
        {editable ? (
          <Tooltip
            title={lgUp ? '' : editMode ? 'Avsluta redigering' : 'Redigera'}
          >
            {lgUp ? (
              <Button
                color={editMode ? 'info' : 'primary'}
                onClick={handleToggleEditMode}
                startIcon={
                  <Icon
                    name={
                      editMode ? 'EditOffOutlined' : 'ModeEditOutlineOutlined'
                    }
                  />
                }
                sx={{ mr: 1 }}
                variant="contained"
              >
                {editMode ? 'Avsluta redigering' : 'Redigeringsläge'}
              </Button>
            ) : (
              <IconButton
                color={editMode ? 'info' : 'primary'}
                onClick={handleToggleEditMode}
                sx={{ mr: 1 }}
                variant="contained"
              >
                <Icon
                  name={
                    editMode ? 'EditOffOutlined' : 'ModeEditOutlineOutlined'
                  }
                />
              </IconButton>
            )}
          </Tooltip>
        ) : null}
        <Tooltip title="Exportera">
          <IconButton onClick={() => handleOpenDialog('export_dashboard')}>
            <Icon name="FileDownloadOutlined" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={
            group.favorite.active
              ? 'Ta bort från favoriter'
              : 'Lägg till i favoriter'
          }
        >
          <IconButton
            onClick={() => {
              tryPutDashboardFavorite(group.id)
            }}
          >
            <Icon
              name="StarOutlined"
              color={group.favorite.active ? 'brand' : 'inherit'}
            />
          </IconButton>
        </Tooltip>
        {isHomepal || isAdmin ? (
          <Tooltip
            title={
              group.status !== DashboardStatus.PUBLISHED
                ? 'Aktivitetsöversikt är endast tillgängligt för publicerade dashboards'
                : 'Aktivitetsöversikt'
            }
          >
            <Box>
              <IconButton
                onClick={() => handleOpenDialog('dashboard_activity')}
                disabled={group.status !== DashboardStatus.PUBLISHED}
              >
                <Icon name="TrendingUpOutlined" />
              </IconButton>
            </Box>
          </Tooltip>
        ) : null}
        {editable ? (
          <Tooltip title="Alternativ">
            <IconButton
              onClick={(e) => {
                handleOpenEditMenu(e)

                e.preventDefault()
              }}
            >
              <Icon name="MoreVertOutlined" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
      {/*-- dialogs --*/}

      {/*-- activity dashboard --*/}
      <ActivityDialog
        dashboardGroupId={group.id}
        open={dialog.open && dialog.type === 'dashboard_activity'}
        onClose={handleCloseDialog}
      />

      {/*-- export dashboard --*/}
      <ExportDialog
        gridRef={gridRef}
        open={dialog.open && dialog.type === 'export_dashboard'}
        onClose={handleCloseDialog}
        title={dashboard.title}
      />

      {/*-- delete dashboard --*/}
      <DeleteDialog
        handleClose={handleCloseDialog}
        handleDelete={(callback: () => void) => {
          tryDeleteDashboard(dialog.id, () => {
            const nextDashboardId = group.dashboards.filter(
              (dashboardId) => dashboardId !== dialog.id
            )[0]

            history.push(`/dashboards/${nextDashboardId}`)
            handleCloseDialog()
            callback()
          })
        }}
        open={dialog.open && dialog.type === 'delete_dashboard'}
        title={groupDashboards.find((item) => item.id === dialog.id)?.title}
        type="flik"
      />

      {/*-- edit dashboard title --*/}
      <EditTitleDialog
        onClose={handleCloseDialog}
        onSubmit={(value) => {
          tryUpdateDashboard(dialog.id, { data: { title: value } })
        }}
        open={dialog.open && dialog.type === 'edit_dashboard_title'}
        title={groupDashboards.find((item) => item.id === dialog.id)?.title}
      />

      {/*-- edit dashboard group --*/}
      <EditDashboardMenu
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        deleteCallback={() => history.push('/dashboards')}
        folders={folders}
        group={group}
        handleClose={handleCloseEditMenu}
        menuAnchorEl={editMenuAnchorEl}
        paperSx={{ mt: 4 }}
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...CompanyGroupActions,
      ...DashboardActions,
      ...DashboardFavoriteActions,
      ...DashboardFolderActions,
      ...DashboardGroupActions,
      ...KPITemplateActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & DashboardHeaderProps

export default connector(DashboardHeader)
