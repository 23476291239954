import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

import { ApiKey } from 'types/GlobalApiKey'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/iframe-api-keys`

export async function getAll() {
  return getWithRedirect<ApiKey[]>(URL)
}

export async function create(name: string) {
  return axios.post<{ data: ApiKey }>(
    URL,
    { data: { name } },
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
