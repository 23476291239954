import { getInternalWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements/dashboards`

export interface InternalDashboardApiResponse {
  kpi: number
  list: string[][]
  bars: {
    labels: string[]
    data: number[]
  }
}

export async function getCoreAction() {
  return getInternalWithRedirect<InternalDashboardApiResponse>(
    `${URL}/core-actions`
  )
}

export async function getUserActivity() {
  return getInternalWithRedirect<InternalDashboardApiResponse>(
    `${URL}/user-activity`
  )
}

export async function getUsers() {
  return getInternalWithRedirect<InternalDashboardApiResponse>(`${URL}/users`)
}

export async function getActivatedUsers() {
  return getInternalWithRedirect<InternalDashboardApiResponse>(
    `${URL}/activated-users`
  )
}
