import React from 'react'

import { ParsedSegmentPath, WidgetObject } from 'types/GlobalWidget'

import OptionsMenu from 'components_new/molecules/OptionsMenu'

import { getOptions } from './utils'

interface SegmentMenuProps {
  anchorPosition: { top: number; left: number } | null
  customSegmentBy?: string | null
  link?: string | null
  onClickExternalLink?: ((link: string | null) => void) | null
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  segments: ParsedSegmentPath[] | null
  setCustomSegmentBy?:
    | ((attributeOptionId: string, segments: ParsedSegmentPath[]) => void)
    | null
  setDetailsDialog?: ((segments: ParsedSegmentPath[]) => void) | null
  setFilters?: ((segments: ParsedSegmentPath[]) => void) | null
  widget: WidgetObject
}

const SegmentMenu = React.forwardRef((props: SegmentMenuProps, ref) => {
  const {
    anchorPosition,
    customSegmentBy,
    link,
    onClickExternalLink,
    onClose,
    segments,
    setCustomSegmentBy,
    setDetailsDialog,
    setFilters,
    widget
  } = props
  const open = Boolean(anchorPosition)

  // check for requirements
  if (!open || !segments || segments.length === 0 || !setFilters) {
    return null
  }

  const title = segments.map((segment) => segment.display_label).join(', ')

  const isPublic = location.pathname.includes('public')

  const options = getOptions(
    widget,
    segments,
    setFilters,
    setDetailsDialog,
    customSegmentBy,
    setCustomSegmentBy,
    onClickExternalLink,
    link,
    isPublic
  )

  return (
    <OptionsMenu
      anchorPosition={anchorPosition || undefined}
      anchorReference="anchorPosition"
      onClose={onClose}
      open={open}
      options={options}
      ref={ref}
      title={title}
    />
  )
})

export default SegmentMenu
