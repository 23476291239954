import React from 'react'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'

import Checklist from '../Checklist'

import { guidelines } from './content'

const UsabilityView = () => {
  return (
    <>
      <Checklist />
      <Box
        sx={{
          p: 4,
          position: 'relative',
          bgcolor: 'background.default',
          borderRadius: 2
        }}
      >
        <Text color="inherit" gutterBottom={true} variant="h4">
          10 rekommendationer
        </Text>
        <Text color="inherit" variant="body1">
          Att bygga en användarvänlig dashboard är inte så lätt. Nedan har vi
          listat några saker att tänka på.
        </Text>
        <List dense={true}>
          {guidelines.map((item, i) => (
            <ListItem key={item.title} sx={{ px: 0 }}>
              <ListItemIcon>
                <Avatar sx={{ width: 32, height: 32 }}>{i + 1}</Avatar>
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                primaryTypographyProps={{
                  fontWeight: 'medium',
                  variant: 'body1'
                }}
                secondary={item.body}
                secondaryTypographyProps={{
                  variant: 'body1'
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
}

export default UsabilityView
