import Axios, { ResponseType } from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'
import { IRealEstate } from 'types/GlobalRealEstate'
import { FileExtension } from 'utils/types'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/sim`

export async function getSIMTables() {
  return getWithRedirect(URL)
}

export async function exportTable(
  id: string,
  extension: FileExtension,
  queryString: string
) {
  const options = {
    ...parseAuthenticationHeader(),
    responseType: 'blob' as ResponseType
  }

  return Axios.get(`${URL}/${id}/export/${extension}?${queryString}`, options)
}

export async function getSIMData(id: string, queryString: string) {
  const url = `${URL}/${id}?${queryString}`

  return getWithRedirect(url)
}

export async function getRealEstates() {
  const url = `${URL}/real-estates`

  return getWithRedirect<IRealEstate[]>(url)
}
