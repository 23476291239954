import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

import {
  IAllocationKey,
  IPatchAllocationKey,
  IPostAllocationKey
} from 'types/GlobalAllocationKey'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/allocation-keys`

export async function getAll() {
  return getWithRedirect<IAllocationKey[]>(URL)
}

export async function create(body: { data: IPostAllocationKey }) {
  return axios.post<{ data: IAllocationKey }>(
    URL,
    body,
    parseAuthenticationHeader()
  )
}

export async function update(id: string, body: { data: IPatchAllocationKey }) {
  return axios.patch<{ data: IAllocationKey }>(
    `${URL}/${id}`,
    body,
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete<void>(`${URL}/${id}`, parseAuthenticationHeader())
}
