import React, { ChangeEvent, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import LinearProgress from 'components_new/atoms/LinearProgress'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import AuthHero from '../AuthHero'

import {
  getPasswordStrenth,
  handleEnter,
  validateNewPasswordLength,
  validateRequired
} from '../utils'

interface ResetPasswordProps {
  confirmationCode: string
  errors: { message: string }[]
  loading: boolean
  onBackToSignIn: () => void
  onResetPassword: (code: string, password: string) => void
  setErrors: (errors: { message: string }[]) => void
}

const ResetPassword = (props: ResetPasswordProps) => {
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(false)

  const validPassword = validateNewPasswordLength(newPassword)
  const [passwordStrength, passwordStrengthText, passwordStrenthColor] =
    getPasswordStrenth(newPassword)

  const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorRepeatPassword(false)
    setNewPassword(e.target.value)
  }

  const handleChangeRepeatNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorRepeatPassword(false)
    setRepeatNewPassword(e.target.value)
  }

  const handleResetPassword = () => {
    const validCode = validateRequired(props.confirmationCode)
    const passwordMatch = newPassword === repeatNewPassword

    if (validCode && passwordMatch) {
      props.onResetPassword(props.confirmationCode, newPassword)
    } else {
      if (!passwordMatch) {
        setErrorRepeatPassword(true)
      }
    }
  }

  return (
    <>
      <AuthHero
        title="Återställ lösenord"
        subtitle="Välj ett nytt lösenord till ditt konto."
      />
      <Box sx={{ mb: 4 }}>
        <TextField
          error={errorRepeatPassword}
          fullWidth
          helperText={
            errorRepeatPassword
              ? 'Lösenorden som du angivit matchar inte.'
              : 'Minst 8 karaktärer långt'
          }
          label={'Nytt lösenord'}
          onChange={handleChangeNewPassword}
          onKeyDown={(e) => handleEnter(e, handleResetPassword)}
          sx={{ mb: 2 }}
          value={newPassword}
          type={'password'}
        />
        <TextField
          error={errorRepeatPassword}
          fullWidth
          label={'Återupprepa nytt lösenord'}
          helperText={
            errorRepeatPassword
              ? 'Lösenorden som du angivit matchar inte.'
              : undefined
          }
          onChange={handleChangeRepeatNewPassword}
          onKeyDown={(e) => handleEnter(e, handleResetPassword)}
          value={repeatNewPassword}
          type={'password'}
          sx={{ mb: 2 }}
        />
        {validPassword ? (
          <>
            <Text sx={{ mb: 1 }} variant="subtitle2">
              Lösenordsstyrka
            </Text>
            <LinearProgress
              color={passwordStrenthColor ? passwordStrenthColor : undefined}
              value={passwordStrength}
              variant="determinate"
            />
            <Text
              color={
                passwordStrenthColor
                  ? `${passwordStrenthColor}.text`
                  : undefined
              }
              sx={{ mb: 4 }}
            >
              {passwordStrengthText}
            </Text>
          </>
        ) : null}
        <Button
          color="brand"
          disabled={!validPassword || !repeatNewPassword}
          fullWidth={true}
          loading={props.loading}
          loadingTitle="Återställer ditt lösenord"
          onClick={handleResetPassword}
          size="large"
          sx={{ mb: 4 }}
        >
          Återställ lösenord
        </Button>
        {props.errors.length > 0 ? (
          <Alert severity={'error'} variant={'filled'}>
            {props.errors[0].message}
          </Alert>
        ) : null}
        <Button
          fullWidth={true}
          onClick={() => {
            props.setErrors([])

            props.onBackToSignIn()
          }}
          startIcon={<Icon name="ArrowBackOutlined" />}
          variant="text"
        >
          Tillbaka till inloggning
        </Button>
      </Box>
    </>
  )
}

ResetPassword.displayName = 'ResetPassword'

export default ResetPassword
