import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <>
      <Skeleton
        sx={{
          height: '60%',
          width: '100%'
        }}
      />
      <Skeleton
        sx={{
          height: '40%',
          width: '100%'
        }}
      />
    </>
  )
}

export default Loading
