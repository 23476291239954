import Axios from 'axios'

import * as Functions from 'helpers/Functions'

const homepal_mdm_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? homepal_mdm_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

export async function whoAmI() {
  const url = `${BASE_URL}who-am-i`
  const header = Functions.parseAuthenticationHeader()

  return Axios.get(url, header)
}
