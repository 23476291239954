import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

import { PatchUserBody, PostUserBody } from 'types/GlobalUser'
import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v2/accounts`

export async function getAll() {
  return getWithRedirect(URL)
}

export async function update(id: string, body: PatchUserBody) {
  return axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function create(body: PostUserBody) {
  return axios.post(URL, body, parseAuthenticationHeader())
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
