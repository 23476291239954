import React, { useState } from 'react'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import NewUserDialog from 'components_new/organisms/dialogs/NewUserDialog'
import Tooltip from 'components_new/atoms/Tooltip'

const HelpButton = () => {
  const [newUserDialogOpen, setNewUserDialogOpen] = useState<boolean>(false)

  return (
    <>
      <Tooltip title="Hur fungerar Homepal?">
        <IconButton onClick={() => setNewUserDialogOpen(true)} size="small">
          <Icon fontSize="small" name="HelpOutlineOutlined" />
        </IconButton>
      </Tooltip>

      {/*-- dialogs --*/}
      <NewUserDialog
        onClose={() => setNewUserDialogOpen(false)}
        open={newUserDialogOpen}
      />
    </>
  )
}

export default HelpButton
