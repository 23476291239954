import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'
import { CreateEmailBody } from 'types/GlobalEmails'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/internal/emails`

export async function getAll() {
  const url = `${URL}`

  return getWithRedirect(url)
}

export async function create(body: CreateEmailBody) {
  return axios.post(URL, { data: body }, parseAuthenticationHeader())
}
