import { LinearProgressProps } from 'components_new/atoms/LinearProgress'
import { KeyboardEvent } from 'react'

export const LOGIN = 'LOGIN'
export const LOGIN_WITH_AZURE = 'LOGIN_WITH_AZURE'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const VERIFICATION_CODE = 'VERIFICATION_CODE'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SIGNED_IN = 'SIGNED_IN'

export function validateNewPasswordLength(password: string) {
  return password.length >= 8
}

function validateNewPasswordSmallCharacter(password: string) {
  return /[a-z]/.test(password)
}

function validateNewPasswordCapsCharacter(password: string) {
  return /[A-Z]/.test(password)
}

function validateNewPasswordNumber(password: string) {
  return /[0-9]/.test(password)
}

export function validateEmail(email: string) {
  return email.includes('@')
}

export function validateRequired(str: string, required = true) {
  if (required) {
    return str.length > 0
  }

  return true
}

export const getPasswordStrenth = (
  password: string
): [number, string | null, LinearProgressProps['color'] | null] => {
  if (!validateNewPasswordLength(password)) {
    // required
    return [0, null, null]
  }

  // Increment strength for each valid character criterion
  const validations = [
    validateNewPasswordSmallCharacter(password),
    validateNewPasswordCapsCharacter(password),
    validateNewPasswordNumber(password)
  ]

  const strength = validations.filter(Boolean).length * 33

  switch (strength) {
  case 66:
    return [66, 'Medel', 'info']
  case 99:
    return [100, 'Stark', 'success']
  default:
    return [33, 'Svag', 'warning']
  }
}

export const handleEnter = (e: KeyboardEvent, f: () => void) => {
  if (e.key === 'Enter') {
    f()
  }
}
