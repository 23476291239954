import { getWithRedirect } from 'utils/axios'

const my_homepal_api =
  process.env.REACT_APP_HOMEPAL_MDM_API_DEV || 'http://localhost:3990/'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? my_homepal_api
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/data-products/source-schemas`

export async function getAll() {
  return getWithRedirect(URL)
}
