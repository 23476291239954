import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import * as AccountStoreActions from 'redux/actions/Accounts'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import * as DashboardFolderStoreActions from 'redux/actions/DashboardFolders'
import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'

import { ApplicationState } from 'redux/Stores/types'

export enum SortByMethod {
  LAST_VIEWED = 'LAST_VIEWED',
  ALPHABETICAL = 'ALPHABETICAL',
  UPDATED_AT = 'UPDATED_AT'
}

// If more props should be used, extend prop with connectedProps
export type DashboardsPageProps = ConnectedProps<typeof connector>

// @TODO: Remove all above. Left due to DashboardsPageProps being used
// elsewhere.
import Finder from 'components_new/organisms/Finder'

import FinderTemplate from 'components_new/templates/FinderTemplate'

const Dashboards = (props: ComponentProps) => {
  const { AuthStore } = props

  useEffect(() => {
    const {
      AccountStore,
      CompanyGroupStore,
      DashboardFolderStore,
      KPIDashboardStore,
      tryGetAllAccounts,
      tryGetAllCompanyGroups,
      tryGetAllDashboards,
      tryGetAllDashboardFolders
    } = props

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }

    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!DashboardFolderStore.fetched) {
      tryGetAllDashboardFolders()
    }

    if (!KPIDashboardStore.fetched && !KPIDashboardStore.fetching) {
      tryGetAllDashboards()
    }
  }, [])

  return (
    <FinderTemplate
      isStockholmshem={
        !!(
          AuthStore.user?.email?.includes('@stockholm.se') ||
          AuthStore.user?.email?.includes('@stockholmtest.se')
        )
      }
    >
      <Finder />
    </FinderTemplate>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  AccountStore: state.AccountStore,
  DashboardFolderStore: state.DashboardFolderStore,
  CompanyGroupStore: state.CompanyGroupStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountStoreActions,
      ...CompanyGroupActions,
      ...DashboardFolderStoreActions,
      ...KPIDashboardStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(Dashboards)
