import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/tag-options`

interface TagOption {
  id: string
  values: string[]
}

export async function getAllTagOptions() {
  return getWithRedirect<TagOption[]>(URL)
}
