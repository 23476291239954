import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import exportToExcel from 'utils/excel'

export function tryExportDataXlsx(
  headers: string[],
  rows: { [key: string]: string | number | null }[],
  fileName: string,
  callback?: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    exportToExcel(headers, rows, fileName)
      .then(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export lyckades' }
        })

        if (callback) {
          callback()
        }
      })
      .catch(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export misslyckades' }
        })
        if (callback) {
          callback(true)
        }
      })
  }
}
