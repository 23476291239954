import React from 'react'

import Paper from 'components_new/atoms/Paper'

import WidgetBase from 'components_new/organisms/Widget/WidgetBase'
import WidgetHeader from 'components_new/organisms/Widget/WidgetHeader'
import WidgetBody from 'components_new/organisms/Widget/WidgetBody'
import WidgetFooter from 'components_new/organisms/Widget/WidgetFooter'

import { CustomColors } from 'types/GlobalCustomization'
import { FormattedWidgetData, WidgetObject } from 'types/GlobalWidget'
import { ThemeProvider } from '@mui/material'
import { getTheme, getWidgetMode } from 'themes'

const SCALE_FACTOR = 0.75

const PreviewWidget = (props: {
  colors?: CustomColors | null
  formattedData: FormattedWidgetData
  widget: WidgetObject
}) => {
  const { colors, formattedData, widget } = props
  const widgetMode = getWidgetMode(colors)

  return (
    <ThemeProvider theme={getTheme(widgetMode, colors)}>
      <Paper
        variant="outlined"
        sx={{ bgcolor: 'background.widget', height: 264, p: 1 }}
      >
        <WidgetBase
          colors={colors}
          editMode={false}
          plain={true}
          scaleFactor={SCALE_FACTOR}
          widgetType={widget.settings.type.selected}
        >
          <WidgetHeader
            editMode={false}
            scaleFactor={SCALE_FACTOR}
            updateWidget={() => {}}
            widget={widget}
          />
          <WidgetBody
            dashboardFilter={[]}
            editMode={true}
            embedded={true}
            fakeLoading={false}
            formattedData={formattedData}
            fullSize={false}
            isAdmin={false}
            loading={false}
            periodFilter={null}
            scaleFactor={SCALE_FACTOR}
            customSegmentBy={null}
            setDashboardFilter={() => {}}
            showUnderlyingContent={() => {}}
            setEdit={() => {}}
            setCustomSegmentBy={() => {}}
            setViewport={() => {}}
            standaloneWidget={true}
            viewport={{
              longitude: widget.settings.viewport.longitude,
              latitude: widget.settings.viewport.latitude,
              pitch: widget.settings.viewport.pitch,
              zoom: widget.settings.viewport.zoom
            }}
            widget={widget}
          />
          <WidgetFooter
            editMode={true}
            loading={false}
            customPeriodFilter={null}
            periodFilter={null}
            scaleFactor={SCALE_FACTOR}
            widget={widget}
          />
        </WidgetBase>
      </Paper>
    </ThemeProvider>
  )
}

export default PreviewWidget
