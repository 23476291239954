import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

import { PatchInternalCustomerBody } from 'types/GlobalCustomer'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v2/customers`

export async function getCustomers() {
  return getWithRedirect(URL)
}

export async function updateCustomer(
  id: string,
  body: PatchInternalCustomerBody
) {
  return axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}
