import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/company-groups`

export async function getAll() {
  return getWithRedirect(URL)
}

export async function create(name: string) {
  return Axios.post(URL, { data: { name } }, parseAuthenticationHeader())
}

export async function update(
  id: string,
  name?: string,
  economySetId?: string | null
) {
  const body: { data: { name?: string; economy_set_id?: string | null } } = {
    data: {}
  }

  if (name) {
    body.data.name = name
  }

  if (economySetId !== undefined) {
    body.data.economy_set_id = economySetId
  }

  return Axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function destroy(id: string) {
  return Axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}

export async function addCompanyToCompanyGroup(id: string, companyId: string) {
  return Axios.post(
    `${URL}/${id}/${companyId}`,
    {},
    parseAuthenticationHeader()
  )
}

export async function removeCompanyFromCompanyGroup(
  id: string,
  companyId: string
) {
  return Axios.delete(`${URL}/${id}/${companyId}`, parseAuthenticationHeader())
}
