import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useParams, useLocation } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'

import * as DashboardActions from 'redux/actions/Dashboards'
import * as KPITemplatesActions from 'redux/actions/KPITemplates'
import * as UtilityActions from 'redux/actions/Utilities'
import * as WidgetActions from 'redux/actions/Widgets'

import { AspectRatio } from 'redux/reducers/Dashboards'

import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import { getDataForWidgets } from './utils'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  CustomPeriodFilter,
  ParsedSegmentPath,
  PeriodFilter,
  SegmentPeriod,
  WidgetObject
} from 'types/GlobalWidget'

import Box from 'components_new/atoms/Box'

import CollapsibleSidebar from 'components_new/molecules/CollapsibleSidebar'
import FixedSideButton from 'components_new/molecules/FixedSideButton'
import LoadingSection from 'components_new/molecules/LoadingSection'

import DashboardUserFilterBar from 'components_new/organisms/DashboardUserFilterBar'
import DashboardUserFilter from 'components_new/organisms/DashboardUserFilter'
import DashboardGrid from 'components_new/organisms/DashboardGrid'
import Widget from 'components_new/organisms/Widget'
import {
  convertWidgetDataToExport,
  formatWidgetData,
  getDetailsQueryParams
} from 'components_new/organisms/Dashboard/utils'
import UnderlyingContentDialog from 'components_new/organisms/UnderlyingContentDialog'

import ExportDialog from 'components_new/organisms/dialogs/ExportDialog'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { QueryParams } from 'redux/api/Widgets'
import {
  getFilterFromSegmentPaths,
  isCustomPeriodFilter,
  isPeriodFilterEnum
} from 'utils/functions'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...KPITemplatesActions,
      ...UtilityActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type ApiAccessDashboardPageProps = ConnectedProps<typeof connector>

const ApiAccessDashboardPage: FC<ApiAccessDashboardPageProps> = (
  props: ApiAccessDashboardPageProps
) => {
  const {
    KPIDashboardStore,
    KPITemplateStore,
    tryExportDataXlsx,
    tryGetAllKPIs
  } = props

  const [dataFetched, setDataFetched] = useState(false)
  const [exportOpen, setExportOpen] = useState(false)
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>([])
  const [periodFilter, setPeriodFilter] = useState<
    PeriodFilter | CustomPeriodFilter | null
  >(null)
  const [customSegmentByMapper, setCustomSegmentByMapper] = useState<{
    [widgetId: string]: string | null
  }>({})
  const [underlyingContent, setUnderlyingContent] = useState<{
    widget: WidgetObject
    initialKpiId: string
    segmentLabel: string | number | null
    queryParams: QueryParams | null
  } | null>(null)
  const gridRef = useRef(null)

  const params = useParams<{ id: string }>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token') as string
  const allowExport = queryParams.get('export') === 'true'
  const allowDashboardFilter = queryParams.get('filter') === 'true'

  const dashboard = KPIDashboardStore.data[params.id]

  const [dashboardWidgets, layout] = useMemo(() => {
    if (KPIDashboardStore.data[params.id]?.widgets) {
      const widgets = Object.values(KPIDashboardStore.data[params.id].widgets)

      return [
        widgets,
        widgets.map((widget) => ({
          x: widget.layout.x,
          y: widget.layout.y,
          w: widget.layout.width,
          h: widget.layout.height,
          i: widget.id
        }))
      ]
    }

    return [[], []]
  }, [KPIDashboardStore.data?.[params.id]?.widgets])

  useEffect(() => {
    const { tryGetOneApiAccessDashboard } = props

    tryGetOneApiAccessDashboard(params.id)

    if (!KPITemplateStore.fetchedKpis && !KPITemplateStore.fetchingKpis) {
      tryGetAllKPIs()
    }
  }, [])

  useEffect(() => {
    if (dashboard && !dataFetched) {
      setDataFetched(true)
      getDataForWidgets(
        props,
        token,
        dashboard,
        [],
        periodFilter,
        customSegmentByMapper,
        undefined,
        true
      )
    }
  }, [KPIDashboardStore.data])

  const handleResetAllTempStates = () => {
    setDashboardFilter([])
    setPeriodFilter(null)
    setCustomSegmentByMapper({})

    getDataForWidgets(props, token, dashboard, [], null, {})
  }

  const handleSetPeriodFilter = (
    period: PeriodFilter | CustomPeriodFilter | null
  ) => {
    setPeriodFilter(period)

    getDataForWidgets(
      props,
      token,
      dashboard,
      dashboardFilter,
      period,
      customSegmentByMapper
    )
  }

  const handleSetDashboardFilter = (
    newDashboardFilter: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const period =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    getDataForWidgets(
      props,
      token,
      dashboard,
      newDashboardFilter,
      period,
      customSegmentByMapper
    )
    setDashboardFilter(newDashboardFilter)

    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }
  }

  const handleSetSegmentBy = (
    widgetId: string,
    attributeOptionId: string | null,
    dbFilter?: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const newSegmentByMapper = {
      ...customSegmentByMapper,
      [widgetId]: attributeOptionId
    }
    const activeDbFilter = dbFilter || dashboardFilter
    const activePeriod =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    setCustomSegmentByMapper(newSegmentByMapper)

    if (dbFilter !== undefined) {
      setDashboardFilter(dbFilter)
    }
    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }

    getDataForWidgets(
      props,
      token,
      dashboard,
      activeDbFilter,
      activePeriod,
      newSegmentByMapper
    )
  }

  const handleSetUnderlyingContent = (
    widget: WidgetObject,
    segmentPaths: ParsedSegmentPath[],
    initialKpiOptionId: string | null
  ) => {
    const initialId = initialKpiOptionId
      ? initialKpiOptionId
      : (widget.settings.kpi_options[0].id as string)

    const segmentFilters = getFilterFromSegmentPaths(
      widget,
      segmentPaths,
      initialId
    )

    const pathWithPeriod = segmentPaths.find((item) => item.period)

    let customPeriodFilter = undefined

    if (pathWithPeriod) {
      const { from_date, to_date } = pathWithPeriod.period as SegmentPeriod

      customPeriodFilter = {
        from: from_date,
        to: to_date
      }
    }

    const contentQueryParams = getDetailsQueryParams(
      widget,
      dashboardFilter,
      customPeriodFilter || periodFilter,
      null,
      segmentFilters,
      null
    )

    setUnderlyingContent({
      widget,
      initialKpiId: initialId,
      segmentLabel: segmentPaths[0]?.display_label,
      queryParams: contentQueryParams
    })
  }

  if (KPIDashboardStore.activeFetchOne !== params.id) {
    // check for loading or error
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {KPIDashboardStore.errorFetchOne === params.id ? (
          <p>Det går inte att hämta dashboarden.</p>
        ) : (
          <LoadingSection titles={LOADING_WIDGET_TITLES} loading={true} />
        )}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <DashboardUserFilterBar
          dashboardFilter={dashboardFilter}
          periodFilter={periodFilter}
          resetAllTempStates={handleResetAllTempStates}
          setDashboardFilter={handleSetDashboardFilter}
          setPeriodFilter={handleSetPeriodFilter}
        />
        <Box
          sx={{
            position: 'relative',
            flex: '1 1 auto',
            height: '100%', // For Chrome 53
            width: '100%' // For Chrome 53
          }}
        >
          <DashboardGrid
            colors={dashboard.white_label_settings}
            editable={false}
            embedded={true}
            hideFooter={true}
            gridRef={gridRef}
            layout={layout}
            ratio={AspectRatio.AUTO}
            title={dashboard.title}
            updateLayout={() => {}}
          >
            {({ scaleFactor }) => {
              return dashboardWidgets.map((widget) => {
                const formattedData = formatWidgetData(widget)

                return (
                  <Box
                    onMouseOver={() => {}}
                    onMouseLeave={() => {}}
                    key={widget.id}
                    sx={{ position: 'relative' }}
                  >
                    <Widget
                      availableSpace={null}
                      colors={dashboard.white_label_settings}
                      customer={null}
                      customPeriodFilter={
                        periodFilter && isCustomPeriodFilter(periodFilter)
                          ? periodFilter
                          : null
                      }
                      dashboardFilter={dashboardFilter}
                      dashboardFilterOptions={[]}
                      editMode={false}
                      embedded={false}
                      enableFullscreen={true}
                      exportWidgetData={() => {
                        const { headers, rows } = convertWidgetDataToExport(
                          widget,
                          formattedData
                        )

                        tryExportDataXlsx(headers, rows, widget.title)
                      }}
                      formattedData={formattedData}
                      isAdmin={false}
                      kpiTemplates={KPITemplateStore.data}
                      loading={!widget.data && !widget.status.broken}
                      periodFilter={
                        periodFilter && isPeriodFilterEnum(periodFilter)
                          ? periodFilter
                          : null
                      }
                      resetAllDashboardTempStates={handleResetAllTempStates}
                      scaleFactor={scaleFactor}
                      setCustomSegmentBy={(...segmentProps) =>
                        handleSetSegmentBy(widget.id, ...segmentProps)
                      }
                      setDashboardFilter={handleSetDashboardFilter}
                      showUnderlyingContent={(...args) =>
                        handleSetUnderlyingContent(widget, ...args)
                      }
                      widget={widget}
                    />
                  </Box>
                )
              })
            }}
          </DashboardGrid>
        </Box>
      </Box>
      {/*-- export dashboard --*/}
      {allowExport ? (
        <>
          <Box
            sx={{
              flex: '1 1 auto',
              minWidth: 0,
              height: '100%',
              position: 'relative'
            }}
          >
            <FixedSideButton
              iconName="FileDownloadOutlined"
              onClick={() => setExportOpen(true)}
              tooltip="Exportera"
              top={allowDashboardFilter ? 56 : 8}
            />
          </Box>
          <ExportDialog
            gridRef={gridRef}
            open={exportOpen}
            onClose={() => setExportOpen(false)}
            title={dashboard.title}
          />
        </>
      ) : null}

      {/*-- filter side bar --*/}
      {allowDashboardFilter ? (
        <CollapsibleSidebar tooltipSuffix="Filter" defaultCollapsed={true}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              overflowY: 'auto'
            }}
          >
            <DashboardUserFilter
              dashboardFilter={dashboardFilter}
              periodFilter={periodFilter}
              resetAllTempStates={handleResetAllTempStates}
              setDashboardFilter={handleSetDashboardFilter}
              setPeriodFilter={handleSetPeriodFilter}
            />
          </Box>
        </CollapsibleSidebar>
      ) : null}

      {/*-- underlying data dialog --*/}
      <ThemeProvider theme={getTheme('light')}>
        <UnderlyingContentDialog
          content={underlyingContent}
          onClose={() => setUnderlyingContent(null)}
          open={Boolean(underlyingContent)}
        />
      </ThemeProvider>
    </Box>
  )
}

export default connector(ApiAccessDashboardPage)
