import { IconNames } from 'components_new/atoms/Icon'
import { ILocationItem } from 'components_new/molecules/LocationItem'
import {
  DashboardGroup,
  DashboardOwnership,
  DashboardReducerType
} from 'redux/reducers/Dashboards'

import { AuthenticationState } from 'types/GlobalAuthentication'
import { DashboardFolderStore } from 'types/GlobalDashboardFolder'
import { AccountRole } from 'types/GlobalUser'

import { getAvailableFolders, getAvailableGroups } from 'utils/functions'

export type SelectType = 'folder' | 'dashboard'

function parseDashboardGroup(
  group: DashboardGroup,
  KPIDashboardStore: DashboardReducerType,
  accountRole: AccountRole
): ILocationItem {
  const dashboards = group.dashboards.map(
    (dashboardId) => KPIDashboardStore.data[dashboardId]
  )
  const onlyOneTab = dashboards.length === 1

  // check users permission to edit dashboard
  const writeAccess =
    accountRole === AccountRole.ADMIN ||
    group.ownership === DashboardOwnership.OWNED_BY_ME

  const disabledHint = !writeAccess
    ? 'Du saknar rättigheter'
    : 'Dashboard är full'

  const groupDisabled =
    !writeAccess || (onlyOneTab && !dashboards[0].available_space)

  return {
    allow: onlyOneTab,
    disabled: groupDisabled,
    disabledHint,
    // if only one tab, then use its id.
    id: onlyOneTab ? dashboards[0].id : group.id,
    name: group.title,
    iconName: 'AnalyticsOutlined' as IconNames,
    // if more than 1 tab then user has to select tab.
    subItems: !onlyOneTab
      ? dashboards.map((dashboard) => ({
        allow: true,
        disabled: !dashboard.available_space,
        disabledHint: disabledHint,
        id: dashboard.id,
        name: dashboard.title,
        iconName: 'TabUnselectedOutlined' as IconNames
      }))
      : undefined
  }
}

export function getOptions(
  KPIDashboardStore: DashboardReducerType,
  DashboardFolderStore: DashboardFolderStore,
  AuthStore: AuthenticationState,
  type: SelectType
): ILocationItem[] {
  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)

  const folders = getAvailableFolders(
    availableGroups,
    DashboardFolderStore.data,
    AuthStore.user
  )

  if (type === 'folder') {
    return folders.map((folder) => ({
      ...folder,
      allow: true,
      iconName: 'FolderOutlined'
    }))
  }
  const accountRole = AuthStore.user?.role ?? AccountRole.VIEW

  const dashboardsWithoutFolder = availableGroups
    .filter((item) => !item.folder_id)
    .map((group) => parseDashboardGroup(group, KPIDashboardStore, accountRole))
    .sort((a, b) => a.name.localeCompare(b.name, 'sv'))

  return [
    ...folders.map((folder) => ({
      allow: false,
      disabled: false,
      iconName: 'FolderOutlined' as IconNames,
      id: folder.id,
      name: folder.name,
      subItems: availableGroups
        .filter((group) => group.folder_id === folder.id)
        .map((group) =>
          parseDashboardGroup(group, KPIDashboardStore, accountRole)
        )
    })),
    ...dashboardsWithoutFolder
  ]
}
