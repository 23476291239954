import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'
import { StatusBannerBody } from 'utils/types'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/internal/status-banner`

export async function getStatusBanner() {
  return getWithRedirect(URL, parseAuthenticationHeader())
}

export async function createStatusBanner(body: StatusBannerBody) {
  return axios.post(URL, { data: body }, parseAuthenticationHeader())
}

export async function updateStatusBanner(id: string, body: StatusBannerBody) {
  return axios.patch(
    `${URL}/${id}`,
    { data: body },
    parseAuthenticationHeader()
  )
}

export async function deleteStatusBanner(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
