import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <Skeleton
      sx={{
        height: '100%',
        width: '100%'
      }}
    />
  )
}

export default Loading
