import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

/**
 * Exports data to an Excel file using ExcelJS.
 * @param headers - Array of header strings for the Excel columns.
 * @param rows - Array of rows.
 * @param fileName - The name of the Excel file to be downloaded.
 */
const exportToExcel = async (
  headers: string[],
  rows: { [key: string]: string | number | null }[],
  fileName: string
): Promise<void> => {
  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Blad 1')

  // Add header row
  worksheet.addRow(headers)

  // add data
  worksheet.addRows(rows.map((row) => Object.values(row)))

  // Generate Excel file and trigger download
  const buffer = await workbook.xlsx.writeBuffer()

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })

  saveAs(blob, `${fileName}.xlsx`)
}

export default exportToExcel
