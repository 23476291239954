import React, { useMemo } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Icon, { IconNames } from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import {
  filterViewsByPeriod,
  TDashboardActivityPeriod,
  translateDashboardActivityPeriod
} from '../utils'
import { TDashboardGroupActivity } from 'types/GlobalDashboardGroup'
import Box from 'components_new/atoms/Box'

interface MetricsProps {
  dashboardGroupId: string
  data: TDashboardGroupActivity
  period: TDashboardActivityPeriod
}

const Metrics = (props: MetricsProps) => {
  const { dashboardGroupId, data, period } = props

  const filteredData = useMemo(() => {
    const { views } = filterViewsByPeriod(data.views, data.created_at, period)

    return {
      numberOfViews: views.length,
      numberOfAccounts: Array.from(new Set(views.map((view) => view.user_id)))
        .length
    }
  }, [period])

  const rankingData = useMemo(() => {
    const dashboardGroups = Object.values(data.dashboard_groups)
      .sort((o1, o2) => o2.total_views - o1.total_views)
      .map((group) => group.id)

    return {
      placement: dashboardGroups.indexOf(dashboardGroupId) + 1,
      total: dashboardGroups.length
    }
  }, [data])

  return (
    <>
      {[
        {
          iconName: 'VisibilityOutlined',
          title: <>Visningar</>,
          subtitle: <>Totalt antal visningar i dashboarden</>,
          value: filteredData.numberOfViews
        },
        {
          iconName: 'GroupOutlined',
          title: <>Användare</>,
          subtitle: <>Unika användare som någon gång har öppnat dashboarden</>,
          value: filteredData.numberOfAccounts
        },
        {
          iconName: 'EmojiEventsOutlined',
          title: <>Ranking</>,
          subtitle: (
            <>Baserat på antal visningar jämfört med andra dashboards</>
          ),
          value: rankingData.placement,
          valueAddition: ` av ${rankingData.total}`
        }
      ].map((metric, i) => {
        const isRanking = i === 2

        return (
          <ThemeProvider key={i} theme={getTheme(isRanking ? 'light' : 'dark')}>
            <Paper
              sx={{
                bgcolor: isRanking ? 'brand.background' : undefined,
                p: 2,
                position: 'relative'
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 2,
                  bgcolor: 'background.overlay'
                }}
              >
                <Icon name={metric.iconName as IconNames} />
              </Box>
              <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                {isRanking ? (
                  <Icon
                    name={'LockOutlined'}
                    sx={{
                      color: 'text.secondary',
                      fontSize: 12,
                      mr: 0.25,
                      verticalAlign: 'middle'
                    }}
                  />
                ) : null}
                <Text color="text.secondary" variant="caption">
                  {isRanking
                    ? translateDashboardActivityPeriod['SINCE_START']
                    : translateDashboardActivityPeriod[period]}
                </Text>
              </Box>
              <Text variant="h3">
                {metric.value}
                {metric.valueAddition ?? null}
              </Text>
              <Text variant="subtitle2">{metric.title}</Text>
              <Text color="text.secondary" variant="caption">
                {metric.subtitle}
              </Text>
            </Paper>
          </ThemeProvider>
        )
      })}
    </>
  )
}

export default Metrics
