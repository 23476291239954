import React, { ReactNode } from 'react'

import PageContainer from 'components_new/organisms/PageContainer'

import FinderNavigation from 'components_new/organisms/FinderNavigation'
import FinderSearch from 'components_new/organisms/FinderSearch'
import InviteButton from 'components_new/organisms/InviteButton'
import NewButton from 'components_new/organisms/NewButton'
import GlobalNavigation from 'components_new/organisms/GlobalNavigation'
import SelectCustomer from 'components_new/organisms/SelectCustomer'

interface FinderPageProps {
  children?: ReactNode
  isStockholmshem: boolean
}

const FinderTemplate = (props: FinderPageProps) => {
  const { children, isStockholmshem } = props

  return (
    <PageContainer
      navContent={
        <>
          <SelectCustomer sx={{ mb: 2 }} />
          <NewButton sx={{ mb: 1 }} />
        </>
      }
      navFooter={<GlobalNavigation />}
      navMenu={<FinderNavigation />}
      topBar={<FinderSearch />}
      topBarActions={isStockholmshem ? null : <InviteButton />}
    >
      {children}
    </PageContainer>
  )
}

export default FinderTemplate
