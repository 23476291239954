import { getInternalWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements/activation`

export async function getActivationDashboard() {
  return getInternalWithRedirect(`${URL}/dashboard`)
}
