export const guidelines = [
  {
    title: 'Var tydlig med vad du vill uppnå',
    body: 'Syftet med din dashboard styr dess design. Se till att du har ett fokus och ett syfte innan du faktiskt börjar bygga.'
  },
  {
    title: 'Inkludera endast det som är viktigt',
    body: 'Allt ska stödja dashboardens avsikt. Skala hellre bort saker så att det som är viktigt framhävs.'
  },
  {
    title: 'Tänk på "data-bläck"-förhållandet',
    body: 'Undvik dekorativa element och för mycket text som inte förmedlar data. Ögat ska dras till mäte- och nyckeltalen.'
  },
  {
    title: 'Avrunda dina siffror',
    body: 'Att vara alltför exakt kan skymma viktiga förändringar.'
  },
  {
    title: 'Gruppera relaterade mått',
    body: 'Gör dina mätetal lätta att hitta.'
  },
  {
    title: 'Var konsekvent',
    body: 'Använd samma visualiseringar och layout för att underlätta jämförelse.'
  },
  {
    title: 'Använd storlek och position för att visa hierarki',
    body: 'Gör det tydligt för tittaren vad som är viktigast.'
  },
  {
    title: 'Ge dina siffror sammanhang',
    body: 'Hjälp tittarna att avgöra om ett tal är bra, dåligt, normalt eller ovanligt.'
  },
  {
    title: 'Använd tydliga etiketter för din målgrupp',
    body: 'Håll dem korta och självförklarande.'
  },
  {
    title: 'Kom ihåg att det är för människor',
    body: 'Det är okej att bryta regler om det ökar engagemanget.'
  }
]
