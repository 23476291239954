import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { parseAuthenticationHeader } from 'helpers/Functions'

/**
 * Get authentication config based on current path.
 */
export function getDefaultConfig() {
  // for api-access we want default authentication to be bearer token.
  const isApiAccess = window.location.pathname.includes('api-access')
  const queryParams = new URLSearchParams(window.location.search)
  const token = isApiAccess ? queryParams.get('token') : null

  return token
    ? { headers: { Authorization: `Bearer ${token}` } }
    : parseAuthenticationHeader()
}

/**
 * Generic function to handle GET requests, including redirected responses.
 * @param url - The API endpoint to fetch data from.
 * @param config - Optional Axios configuration options,
 * if given it will replace default config.
 * @returns A promise resolving to the final data.
 */
export async function getWithRedirect<T = any>(
  url: string,
  config?: AxiosRequestConfig
): Promise<{
  data: { data: T }
}> {
  const response = await Axios.get<{ data: T; redirected_to?: string }>(
    url,
    config ?? getDefaultConfig()
  )

  // Check for a redirection and fetch from the redirected URL if present
  if (response.data.redirected_to) {
    const redirectedResponse = await Axios.get<{ data: T }>(
      response.data.redirected_to
    )

    return redirectedResponse
  }

  return response
}

/**
 * Generic function to handle GET requests, including redirected responses.
 * Used for internal endpoints which dont include "data" in response.
 * ex. measurements
 * @param url - The API endpoint to fetch data from.
 * @returns A promise resolving to the final data.
 */
export async function getInternalWithRedirect<T = any>(
  url: string
): Promise<AxiosResponse<T>> {
  const response = await Axios.get<T & { redirected_to?: string }>(
    url,
    parseAuthenticationHeader()
  )

  // Check for a redirection and fetch from the redirected URL if present
  if (response.data.redirected_to) {
    const redirectedResponse = await Axios.get<T>(response.data.redirected_to)

    return redirectedResponse
  }

  return response
}
