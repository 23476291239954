import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/sim-renamings`

export async function getAll() {
  return getWithRedirect(URL)
}

export interface PutSimRenaming {
  name: string | null
  attribute_id: string
  fact_dimension_linker_id: string | null
}

export async function put(body: PutSimRenaming[]) {
  return Axios.put(URL, { data: body }, parseAuthenticationHeader())
}
