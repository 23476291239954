import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import SplitButton from 'components_new/molecules/SplitButton'

interface ActionsProps {
  showActions: boolean
  isAdmin: boolean
  isStockholmshem: boolean
  isPublic: boolean
  onClose: () => void
  publicLink: string
  setTab: (value: 'embed' | 'email' | 'default') => void
  tab: 'embed' | 'email' | 'default'
}

const Actions = (props: ActionsProps) => {
  const {
    showActions,
    isAdmin,
    isPublic,
    isStockholmshem,
    onClose,
    publicLink,
    setTab,
    tab
  } = props

  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyCurrentUrl = () => {
    const currentUrl = window.location.href

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => setShowCopiedMessage(true))
      .catch((err) => console.error('Failed to copy current URL:', err))
  }

  const copyPublicLink = () => {
    navigator.clipboard
      .writeText(publicLink)
      .then(() => setShowCopiedMessage(true))
      .catch((err) => console.error('Failed to copy public link:', err))
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1
          }}
        >
          {tab === 'default' ? (
            <>
              {showActions ? (
                <>
                  <SplitButton
                    onClick={copyCurrentUrl}
                    options={[
                      {
                        disabled: !isPublic,
                        iconName: 'PublicOutlined',
                        title: 'Kopiera publik länk',
                        onClick: copyPublicLink
                      }
                    ]}
                    startIcon={<Icon name="Link" />}
                    title={'Kopiera länk'}
                  />
                  {isStockholmshem ? null : (
                    <Button
                      startIcon={<Icon name="MailOutlined" />}
                      variant="outlined"
                      onClick={() => setTab('email')}
                    >
                      Bjud in
                    </Button>
                  )}
                  {isAdmin ? (
                    <Button
                      startIcon={<Icon name="CodeOutlined" />}
                      variant="outlined"
                      onClick={() => setTab('embed')}
                    >
                      Bädda in
                    </Button>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </Box>
        <Button onClick={onClose} variant="text">
          Stäng
        </Button>
      </Box>

      {/* ----- Feedback ----- */}
      <Portal>
        <Snackbar
          open={showCopiedMessage}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setShowCopiedMessage(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </>
  )
}

export default Actions
