import { getWithRedirect } from 'utils/axios'
import { FlawType } from 'types/GlobalSimFlaw'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const FLAW_URL = `${BASE_URL}v1/internal/sim-flaws`
const FLAW_SETTINGS_URL = `${BASE_URL}v1/internal/sim-flaw-settings`

export async function getSimFlaws(
  customerId: string,
  tables: string[],
  offset: number,
  flawType: FlawType | null
) {
  let url = `${FLAW_URL}?customer_id=${customerId}&offset=${offset}`

  if (tables.length > 0) {
    url += `&table_name=${tables.join(',')}`
  }

  if (flawType) {
    url += `&type=${flawType}`
  }

  return getWithRedirect(url)
}

export async function getSimFlawSettings() {
  return getWithRedirect(FLAW_SETTINGS_URL)
}
