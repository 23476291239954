import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Checkbox from 'components_new/atoms/Checkbox'
import Collapse from 'components_new/atoms/Collapse'
import Link from 'components_new/atoms/Link'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import AuthHero from '../AuthHero'
import ShemEmailHint from './ShemEmailHint'

import { handleEnter, validateEmail, validateRequired } from '../utils'

const REMEMBER_ME_ITEM_KEY = 'remember-me'

interface SignInProps {
  errors: { message: string }[]
  loading: boolean
  onForgetPassword: () => void
  onSignIn: (
    email: string,
    password: string,
    rememberMe: boolean,
    onlyEmail?: boolean
  ) => Promise<boolean>
  onSignInWithMicrosoft: () => void
  setErrors: (errors: { message: string }[]) => void
}

const SignIn = (props: SignInProps) => {
  const [email, setEmail] = useState('')
  const [emailGiven, setEmailGiven] = useState(false)
  const [errorInEmail, setErrorInEmail] = useState(false)
  const [password, setPassword] = useState('')
  const [errorInPassword, setErrorInPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const passwordRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // load remember me from local storage
    const storedRememberMe =
      localStorage.getItem(REMEMBER_ME_ITEM_KEY) === 'true'

    setRememberMe(storedRememberMe)
  }, [])

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorInEmail(false)
    setEmail(e.target.value)
  }

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    props.setErrors([])
    setErrorInPassword(false)
    setPassword(e.target.value)
  }

  const handleSignIn = async () => {
    const validEmail = validateEmail(email)

    if (!validEmail) {
      setErrorInEmail(true)
    }

    if (validEmail && emailGiven) {
      const validPassword = validateRequired(password)

      if (validEmail && validPassword) {
        await props.onSignIn(email, password, rememberMe)
      } else if (!validPassword) {
        setErrorInPassword(true)
      }
    } else if (
      email.includes('@homepal.se') &&
      // check for love SSO
      email !== 'love.malmros@homepal.se'
    ) {
      setEmailGiven(true)
    } else if (validEmail) {
      const skipRedirect = await props.onSignIn(
        email,
        password,
        rememberMe,
        true
      )

      // If redirecting we skip setting email given
      // we don't want to show
      setEmailGiven(skipRedirect)
    }
  }

  useEffect(() => {
    // Focus password input after emailGiven becomes true
    if (emailGiven && passwordRef.current) {
      passwordRef.current.focus()
    }
  }, [emailGiven])

  const handleRememberMe = () => {
    if (rememberMe) {
      localStorage.removeItem(REMEMBER_ME_ITEM_KEY)
    } else {
      localStorage.setItem(REMEMBER_ME_ITEM_KEY, 'true')
    }

    setRememberMe(!rememberMe)
  }

  return (
    <Box sx={{ minHeight: 500, paddingTop: '96px' }}>
      <AuthHero
        title="Välkommen tillbaka!"
        subtitle="Ange din email för att logga in."
      />
      <Box sx={{ mb: 4, maxWidth: 400 }}>
        <Box sx={{ mb: 2 }}>
          <TextField
            error={errorInEmail}
            fullWidth
            helperText={errorInEmail ? 'Ange en giltig email' : undefined}
            label={'Email'}
            onChange={handleChangeEmail}
            onKeyDown={(e) => handleEnter(e, handleSignIn)}
            value={email}
          />
          {/*-- Custom hint for Stockholmshem --*/}
          <ShemEmailHint
            open={
              props.errors.length > 0 && email.includes('@stockholmshem.se')
            }
          />
          <Collapse in={emailGiven}>
            <TextField
              error={errorInPassword}
              fullWidth
              helperText={errorInPassword ? 'Ange ditt lösenord' : undefined}
              label={'Lösenord'}
              onChange={handleChangePassword}
              onKeyDown={(e) => handleEnter(e, handleSignIn)}
              value={password}
              type={'password'}
              inputRef={passwordRef}
              sx={{
                mt: 2,
                mb: 0.5
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Checkbox checked={rememberMe} onClick={handleRememberMe} />
                <Text variant="body2">Kom ihåg mig</Text>
              </Box>
              <Link
                sx={{ align: 'right' }}
                onClick={() => {
                  props.setErrors([])

                  props.onForgetPassword()
                }}
              >
                Glömt ditt lösenord?
              </Link>
            </Box>
          </Collapse>
        </Box>
        <Button
          color="brand"
          fullWidth={true}
          loading={props.loading}
          loadingTitle="Loggar in"
          onClick={handleSignIn}
          size="large"
        >
          {emailGiven ? 'Logga in' : 'Fortsätt'}
        </Button>
      </Box>
      {props.errors.length > 0 && !email.includes('@stockholmshem.se') ? (
        <Alert severity={'error'} variant={'outlined'} sx={{ mb: 4 }}>
          {props.errors[0].message}
        </Alert>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Text align="center" color="text.secondary" variant="body2">
          Har du inget konto?{' '}
          <Link href={'mailto:hej@homepal.se'}>Kontakta oss</Link>
        </Text>
      </Box>
    </Box>
  )
}

SignIn.displayName = 'SignIn'

export default SignIn
