import React, { FC } from 'react'

import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Fab from 'components_new/atoms/Fab'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'
import { useTheme } from '@mui/material'

interface FixedSideButtonProps {
  badge?: boolean
  iconName: IconNames
  onClick: () => void
  tooltip?: string
  top?: number
}

const FixedSideButton: FC<FixedSideButtonProps> = (props) => {
  const { badge = false, iconName, onClick, tooltip, top = 8 } = props
  const theme = useTheme()

  return (
    <Tooltip title={tooltip}>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top,
          overflow: 'hidden'
        }}
      >
        <Fab
          color="neutral"
          onClick={onClick}
          size="small"
          sx={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            pl: 0.25,
            justifyContent: 'flex-start',
            transition: 'transform 0.25s',
            transitionTimingFunction: theme.transitions.easing.easeInOut,
            transform: 'translateX(16px)',
            '&:hover': {
              transform: 'translateX(0px)'
            }
          }}
        >
          <Badge
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            color="primary"
            variant="dot"
            invisible={!badge}
          >
            <Icon name={iconName} />
          </Badge>
        </Fab>
      </Box>
    </Tooltip>
  )
}

export default FixedSideButton
