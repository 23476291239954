import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

import {
  DashboardFolderPatchBody,
  DashboardFolderPostBody
} from 'types/GlobalDashboardFolder'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/dashboard-folders`

export async function create(body: DashboardFolderPostBody) {
  return Axios.post(URL, body, parseAuthenticationHeader())
}

export async function getAll() {
  return getWithRedirect(URL)
}

export async function update(id: string, body: DashboardFolderPatchBody) {
  return Axios.patch(`${URL}/${id}`, body, parseAuthenticationHeader())
}

export async function destroy(id: string) {
  return Axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
