import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/economy-sets`

export async function getAll() {
  return getWithRedirect(URL)
}

export async function update(id: string, name: string) {
  return axios.patch(
    `${URL}/${id}`,
    { data: { name } },
    parseAuthenticationHeader()
  )
}

export async function create(name: string, csvFile: Blob) {
  const formData = new FormData()

  formData.append('file', csvFile)
  formData.append('name', name)

  return axios.post(URL, formData, parseAuthenticationHeader())
}

export async function upload(id: string, csvFile: Blob) {
  const formData = new FormData()

  formData.append('file', csvFile)

  return axios.put(
    `${URL}/${id}/economy-accounts`,
    formData,
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
