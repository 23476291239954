import React, { useState, useRef, useEffect } from 'react'

import Dashboard from 'components_new/organisms/Dashboard'
import NewDashboardFromExampleDialog from 'components_new/organisms/dialogs/NewDashboardFromExampleDialog'

import DashboardTemplate from 'components_new/templates/DashboardTemplate'

import { DashboardFilter } from 'types/GlobalDashboardFilter'

import * as AccountStoreActions from 'redux/actions/Accounts'
import * as DashboardFolderStoreActions from 'redux/actions/DashboardFolders'
import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'
import * as WidgetActions from 'redux/actions/Widgets'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'

import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getDataForWidgets } from 'components_new/organisms/Dashboard/utils'
import { usePrevious } from 'helpers/Functions'

const DashboardPage = (props: ComponentProps) => {
  const params = useParams<{ id: string }>()
  const dashboard = props.KPIDashboardStore.data[params.id]
  const previousShowAs = usePrevious(props.CompanyGroupStore.showAs)

  const history = useHistory()
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const newlyCreated = searchParams.get('new_dashboard')

  // states
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>([])
  const [periodFilter, setPeriodFilter] = useState<
    PeriodFilter | CustomPeriodFilter | null
  >(null)
  const [customSegmentByMapper, setCustomSegmentByMapper] = useState<{
    [widgetId: string]: string | null
  }>({})
  const [editMode, setEditMode] = useState(false)

  const gridRef = useRef(null)

  useEffect(() => {
    const {
      AccountStore,
      CompanyGroupStore,
      DashboardFolderStore,
      KPIDashboardStore,
      tryGetAllAccounts,
      tryGetAllCompanyGroups,
      tryGetAllDashboards,
      tryGetAllDashboardFolders
    } = props

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }

    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!DashboardFolderStore.fetched) {
      tryGetAllDashboardFolders()
    }

    if (!KPIDashboardStore.fetched && !KPIDashboardStore.fetching) {
      tryGetAllDashboards()
    }
  }, [])

  // Reset on dashboard change.
  useEffect(() => {
    if (Boolean(newlyCreated)) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [params.id])

  const handleToggleEditMode = () => {
    if (Boolean(newlyCreated)) {
      history.replace(`/dashboards/${params.id}`)
    }

    setEditMode(!editMode)
  }

  // If we change tab, open a dashboard or the dashboard
  // is fetched we update dashboard data and reset filters.
  useEffect(() => {
    if (dashboard) {
      setDashboardFilter([])
      setPeriodFilter(null)
      getDataForWidgets(
        props.tryGetOneWidget,
        dashboard,
        [],
        null,
        props.CompanyGroupStore.showAs,
        customSegmentByMapper,
        true
      )
    }
  }, [props.KPIDashboardStore.activeFetchOne])

  // If a dashboard is open and user change company group, update dashboard.
  useEffect(() => {
    const parsedPrevious = previousShowAs || null

    if (dashboard && parsedPrevious !== props.CompanyGroupStore.showAs) {
      getDataForWidgets(
        props.tryGetOneWidget,
        dashboard,
        dashboardFilter,
        periodFilter,
        props.CompanyGroupStore.showAs,
        customSegmentByMapper
      )
    }
  }, [props.CompanyGroupStore.showAs])

  const handleSetPeriodFilter = (
    period: PeriodFilter | CustomPeriodFilter | null
  ) => {
    setPeriodFilter(period)

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      dashboardFilter,
      period,
      props.CompanyGroupStore.showAs,
      customSegmentByMapper,
      false,
      true
    )
  }

  const handleSetDashboardFilter = (
    newDashboardFilter: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const period =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      newDashboardFilter,
      period,
      props.CompanyGroupStore.showAs,
      customSegmentByMapper
    )

    setDashboardFilter(newDashboardFilter)

    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }
  }

  const handleSetSegmentBy = (
    widgetId: string,
    attributeOptionId: string | null,
    dbFilter?: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const newSegmentByMapper = {
      ...customSegmentByMapper,
      [widgetId]: attributeOptionId
    }
    const activeDbFilter = dbFilter || dashboardFilter
    const activePeriod =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    setCustomSegmentByMapper(newSegmentByMapper)

    if (dbFilter !== undefined) {
      setDashboardFilter(dbFilter)
    }
    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      activeDbFilter,
      activePeriod,
      props.CompanyGroupStore.showAs,
      newSegmentByMapper
    )
  }

  const handleResetAllTempStates = () => {
    // filters
    setDashboardFilter([])
    setPeriodFilter(null)

    // segments
    setCustomSegmentByMapper({})

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      [],
      null,
      props.CompanyGroupStore.showAs,
      {}
    )
  }

  return (
    <DashboardTemplate
      dashboardFilter={dashboardFilter}
      gridRef={gridRef}
      periodFilter={periodFilter}
      resetAllTempStates={handleResetAllTempStates}
      setDashboardFilter={handleSetDashboardFilter}
      setPeriodFilter={handleSetPeriodFilter}
      editMode={editMode}
      handleToggleEditMode={handleToggleEditMode}
    >
      <Dashboard
        customSegmentByMapper={customSegmentByMapper}
        dashboardFilter={dashboardFilter}
        gridRef={gridRef}
        periodFilter={periodFilter}
        resetAllTempStates={handleResetAllTempStates}
        setDashboardFilter={handleSetDashboardFilter}
        setPeriodFilter={handleSetPeriodFilter}
        setCustomSegmentBy={handleSetSegmentBy}
        editMode={editMode}
      />
      <NewDashboardFromExampleDialog />
    </DashboardTemplate>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  DashboardFolderStore: state.DashboardFolderStore,
  CompanyGroupStore: state.CompanyGroupStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountStoreActions,
      ...CompanyGroupActions,
      ...DashboardFolderStoreActions,
      ...KPIDashboardStoreActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(DashboardPage)
