import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { getWithRedirect } from 'utils/axios'
import { VitecView, VitecViewPost } from 'types/GlobalVitecView'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v2/connections/vitec-views`

export async function getAll(activeSourceId: string) {
  return getWithRedirect<VitecView[]>(`${URL}/${activeSourceId}`)
}

export async function create(body: VitecViewPost) {
  return Axios.post<{ data: VitecView }>(
    URL,
    { data: body },
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return Axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
