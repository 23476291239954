import React, { ReactNode } from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import * as NavigationMenuStoreActions from 'redux/actions/NavigationMenu'

import { ThemeProvider, useTheme } from '@mui/material'
import { getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Fab from 'components_new/atoms/Fab'
import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

import DynamicLogotype from 'components_new/molecules/DynamicLogotype'

import HelpButton from 'components_new/organisms/HelpButton'
import ProfileAvatar from 'components_new/organisms/ProfileAvatar'

interface TopBarProps {
  actions?: ReactNode
  children?: ReactNode
  navLock?: boolean
  noGutter?: boolean
  sx?: object
  title?: ReactNode
}

const TopBar = (props: ComponentProps) => {
  const {
    actions,
    children,
    NavigationMenuStore,
    navLock,
    noGutter,
    sx,
    title,
    toggleNavigationOpen
  } = props

  const navOpen = navLock ?? NavigationMenuStore.open
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 64,
        width: '100%',
        ...sx
      }}
    >
      <ThemeProvider theme={title ? getTheme('light') : theme}>
        <Box
          sx={{
            position: 'relative',
            pt: 1.5,
            pl: navOpen ? 1.5 : 1,
            minWidth: navOpen ? 260 : 56,
            flexBasis: navOpen ? '8%' : undefined,
            height: '100%',
            bgcolor: 'background.default'
          }}
        >
          {title ? title : <DynamicLogotype collapsed={!navOpen} />}
        </Box>
      </ThemeProvider>

      {navLock ? null : (
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            width: 1,
            py: 1,
            flex: 0
          }}
        >
          <Divider orientation="vertical" />
          <Tooltip title={navOpen ? 'Minska sidopanel' : 'Öppna sidopanel'}>
            <Box
              sx={{
                position: 'absolute',
                right: -40,
                top: 12,
                overflow: 'hidden'
              }}
            >
              <Fab
                color="neutral"
                onClick={() => toggleNavigationOpen()}
                size="small"
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  pr: 0.25,
                  justifyContent: 'flex-end',
                  transition: 'transform 0.25s',
                  transitionTimingFunction: theme.transitions.easing.easeInOut,
                  transform: 'translateX(-16px)',
                  '&:hover': {
                    transform: 'translateX(0px)'
                  }
                }}
              >
                <Icon
                  name={
                    navOpen ? 'NavigateBeforeOutlined' : 'NavigateNextOutlined'
                  }
                />
              </Fab>
            </Box>
          </Tooltip>
        </Box>
      )}

      <Box
        sx={{
          p: noGutter ? 0 : 1,
          pl: 4,
          flex: '1 1 auto',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          minWidth: 0
        }}
      >
        {children}
      </Box>

      <Box
        sx={{
          p: 1,
          pr: 2,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          flex: 0
        }}
      >
        {actions ? (
          <>
            <Divider flexItem={true} orientation="vertical" />
            {actions}
          </>
        ) : null}
        <Divider flexItem={true} orientation="vertical" />
        <HelpButton />
        <ProfileAvatar />
      </Box>
    </Box>
  )
}

/*-- redux --*/
interface ComponentDispatchProps {
  toggleNavigationOpen(): (dispatch: Dispatch) => void
}

const mapStateToProps = (state: ApplicationState) => ({
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...NavigationMenuStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  ComponentDispatchProps &
  TopBarProps

export default connector(TopBar)
