import { Dashboard } from 'redux/reducers/Dashboards'
import { ApiAccessDashboardPageProps } from '.'

import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { getWidgetQueryParams } from 'components_new/organisms/Dashboard/utils'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'

export const getDataForWidgets = (
  props: ApiAccessDashboardPageProps,
  token: string,
  dashboard: Dashboard,
  dashboardFilter: DashboardFilter,
  periodFilter: PeriodFilter | CustomPeriodFilter | null,
  segmentByMapper: { [widgetId: string]: string | null },
  rawQueryParams = '',
  ignoreLockedDateFilter = false
) => {
  const { tryGetOneApiAccessWidget } = props

  Object.values(dashboard.widgets)
    .filter((widget) => {
      if (ignoreLockedDateFilter) {
        return !widget.settings.date_filter_is_locked
      }

      return true
    })
    .forEach((widget) => {
      const segmentBy = segmentByMapper[widget.id] || null

      const queryParams = getWidgetQueryParams(
        widget,
        dashboardFilter,
        periodFilter,
        null,
        segmentBy,
        false
      )

      if (dashboardFilter.length === 0 || queryParams)
        tryGetOneApiAccessWidget(
          widget.id,
          token,
          widget.settings.type.selected,
          widget.settings.show_total,
          segmentBy ? [] : widget.settings.segment_by.additional,
          dashboard.id,
          queryParams,
          rawQueryParams
        )
    })
}
