import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/public/status-banner`

export async function getStatusBanner(customerId: string, isPublic?: boolean) {
  let url = `${URL}/${customerId}`

  if (isPublic) {
    url += '?is_public=true'
  }

  return getWithRedirect(url)
}
