import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import * as WidgetActions from 'redux/actions/Widgets'

import { LayoutItem } from 'redux/reducers/Dashboards'
import { ApplicationState } from 'redux/Stores/types'

import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import IconTip from 'components_new/molecules/IconTip'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Tooltip from 'components_new/atoms/Tooltip'

import NotificationSettings from './NotificationSettings'

import CopyToDashboardDialog from '../dialogs/CopyToDashboardDialog'
import DeleteDialog from '../dialogs/DeleteDialog'
import EmbeddedDialog from '../dialogs/EmbeddedDialog'
import InformationDialog from '../dialogs/InformationDialog'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import { KpiTemplateRedux } from 'types/GlobalKpiTemplates'
import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'
import { DETAILS_DISABLE_TEXT } from 'utils/constants'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

const NON_DATA_WIDGET_TYPES = [
  WidgetType.TEXT,
  WidgetType.COMMENT,
  WidgetType.IMAGE
]

interface WidgetMenuProps {
  availableSpace: LayoutItem | null
  customSegmentBy: string | null
  dashboardFilterOptions: ParsedDashboardFilterOptions[]
  editMode: boolean
  exportWidgetData: () => void
  formattedData: FormattedWidgetData
  isHomepalOrAdmin: boolean
  kpiTemplates: KpiTemplateRedux
  loading: boolean
  embedded: boolean
  enableFullscreen?: boolean
  fullSize: boolean
  scaleFactor: number
  setEdit: () => void
  setFullSizeDialogOpen: (arg0: boolean) => void
  showMenu?: boolean
  showUnderlyingContent: (
    segmentPaths: ParsedSegmentPath[],
    initialKpiOptionId: string | null
  ) => void
  widget: WidgetObject
}

export type ComponentProps = ConnectedProps<typeof connector> & WidgetMenuProps

const WidgetMenu = (props: ComponentProps) => {
  const {
    availableSpace,
    customSegmentBy,
    dashboardFilterOptions,
    editMode,
    embedded,
    enableFullscreen,
    exportWidgetData,
    formattedData,
    fullSize,
    isHomepalOrAdmin,
    scaleFactor,
    setEdit,
    setFullSizeDialogOpen,
    showMenu = true,
    showUnderlyingContent,
    tryCreateWidget,
    tryDeleteWidget,
    tryUpdateWidget,
    kpiTemplates,
    loading,
    widget
  } = props

  const [informationDialogOpen, setInformationDialogOpen] =
    useState<boolean>(false)

  const [copyToDashboardDialogOpen, setCopyToDashboardDialogOpen] =
    useState<boolean>(false)

  const [anchorPosition, setAnchorPosition] = useState<{
    x: number
    y: number
  } | null>(null)
  const open = Boolean(anchorPosition)

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()

    setAnchorPosition({ x: event.clientX, y: event.clientY })
  }

  const handleClose = () => {
    setAnchorPosition(null)
  }

  useEffect(() => {
    if (!showMenu) {
      handleClose()
    }
  }, [showMenu])

  const hideDetails = useMemo(() => {
    return !!widget.settings.kpi_options.every(
      (option) => !option.can_show_details
    )
  }, [widget.settings.kpi_options])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [embeddedDialogOpen, setEmbeddedDialogOpen] = useState(false)

  const type = widget.settings.type.selected

  const widgetWithData = !NON_DATA_WIDGET_TYPES.includes(type)

  const ICON_BUTTON_SIZE = fullSize ? 32 : 26
  const ICON_SIZE = fullSize ? 24 : 20

  return (
    <>
      {!embedded ||
      (enableFullscreen &&
        type !== WidgetType.TEXT &&
        type !== WidgetType.COMMENT) ? (
            <Box
              className="widget-menu"
              sx={{
                position: 'absolute',
                height: 'auto',
                width: 'auto',
                top: 0,
                right: 0,
                transition: 'all 0.5s ease',
                pt: 1.5 * scaleFactor,
                px: 1.5 * scaleFactor,
                pb: 0.75 * scaleFactor,
                zIndex: 3
              }}
            >
              {/*-- buttons --*/}
              <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                {type !== WidgetType.TEXT && type !== WidgetType.COMMENT ? (
                  <IconButton
                    color="disabled"
                    onClick={() => {
                      setFullSizeDialogOpen(!fullSize)
                    }}
                    onMouseDown={(event) => event.stopPropagation()}
                    size="small"
                    sx={{
                      width: ICON_BUTTON_SIZE,
                      height: ICON_BUTTON_SIZE
                    }}
                  >
                    <Icon
                      name={!fullSize ? 'ZoomInOutlined' : 'ZoomOutOutlined'}
                      sx={{
                        fontSize: ICON_SIZE
                      }}
                    />
                  </IconButton>
                ) : null}
                {/* Fake feature for sales and review */}
                {!customSegmentBy ? (
                  <NotificationSettings
                    buttonSize={ICON_BUTTON_SIZE}
                    embedded={embedded}
                    iconSize={ICON_SIZE}
                    loading={loading}
                    widget={widget}
                  />
                ) : null}
                {!embedded &&
            !customSegmentBy &&
            editMode &&
            !loading &&
            type !== WidgetType.TEXT &&
            type !== WidgetType.COMMENT ? (
                      <IconButton
                        color="disabled"
                        onClick={() => setEdit()}
                        onMouseDown={(event) => event.stopPropagation()}
                        size="small"
                        sx={{
                          width: ICON_BUTTON_SIZE,
                          height: ICON_BUTTON_SIZE
                        }}
                      >
                        <Icon
                          fontSize="small"
                          name="Edit"
                          sx={{
                            fontSize: ICON_SIZE
                          }}
                        />
                      </IconButton>
                    ) : null}
                {!embedded ? (
                  <IconButton
                    color="disabled"
                    onClick={handleClick}
                    onMouseDown={(event) => event.stopPropagation()}
                    size="small"
                    sx={{
                      width: ICON_BUTTON_SIZE,
                      height: ICON_BUTTON_SIZE
                    }}
                  >
                    <Icon
                      fontSize="small"
                      name="MoreVertOutlined"
                      sx={{
                        fontSize: ICON_SIZE
                      }}
                    />
                  </IconButton>
                ) : null}
              </Box>

              {/*-- background --*/}
              <Box
                sx={{
                  bgcolor: 'background.widget',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: 0,
                  right: 0,
                  opacity: 0.9,
                  borderBottomLeftRadius: 8 * scaleFactor,
                  borderBottomRightRadius: customSegmentBy
                    ? 8 * scaleFactor
                    : undefined,
                  zIndex: -1
                }}
              />
            </Box>
          ) : null}

      {/*-- menu --*/}
      <ThemeProvider theme={getTheme('light')}>
        <Menu
          // anchorEl={anchorEl}
          disableScrollLock
          // hideBackdrop={true}
          anchorReference="anchorPosition"
          anchorPosition={
            anchorPosition
              ? { top: anchorPosition?.y, left: anchorPosition?.x }
              : undefined
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handleClose}
          onMouseDown={(e) => e.stopPropagation()}
          open={open && showMenu}
          PaperProps={{
            sx: {
              width: 360
            }
          }}
          MenuListProps={{
            pointerEvents: 'auto'
          }}
          sx={{
            width: 360
          }}
        >
          {widgetWithData ? (
            <MenuItem
              onClick={() => {
                handleClose()

                setInformationDialogOpen(true)
              }}
            >
              <ListItemIcon>
                <Icon name="InfoOutlined" />
              </ListItemIcon>
              <ListItemText primary="Visa information" />
            </MenuItem>
          ) : null}
          {widgetWithData ? (
            <MenuItem
              onClick={() => {
                handleClose()

                showUnderlyingContent([], null)
              }}
              disabled={hideDetails}
            >
              <ListItemIcon>
                <Icon name="TableChartOutlined" />
              </ListItemIcon>
              <ListItemText primary="Visa innehåll" />
              {hideDetails ? (
                <IconTip
                  edge="end"
                  placement="right"
                  sx={{ ml: 1 }}
                  title={DETAILS_DISABLE_TEXT}
                />
              ) : null}
            </MenuItem>
          ) : null}
          {widgetWithData ? <Divider /> : null}
          {widgetWithData &&
          type !== WidgetType.KEY_FIGURE &&
          type !== WidgetType.FUNNEL &&
          !loading ? (
                <MenuItem
                  onClick={() => {
                    handleClose()

                    exportWidgetData()
                  }}
                >
                  <ListItemIcon>
                    <Icon name="Download" />
                  </ListItemIcon>
                  <ListItemText primary="Exportera till Excel" />
                </MenuItem>
              ) : null}
          {editMode && widgetWithData && isHomepalOrAdmin ? (
            <MenuItem
              onClick={() => {
                handleClose()
                setEmbeddedDialogOpen(true)
              }}
            >
              <ListItemIcon>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={!widget.settings.embeddable}
                >
                  <Icon name="CodeOutlined" />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Bädda in" />
            </MenuItem>
          ) : null}
          {editMode && widgetWithData ? <Divider /> : null}
          {editMode ? (
            <Tooltip title={availableSpace ? null : 'Din dashboard är full.'}>
              <Box>
                <MenuItem
                  onClick={() => {
                    handleClose()

                    if (availableSpace) {
                      tryCreateWidget({
                        data: {
                          dashboard_id: widget.dashboard_id,
                          kpi_id: null,
                          widget_id: widget.id,
                          width: availableSpace.w,
                          height:
                            type === WidgetType.TEXT ? 1 : availableSpace.h,
                          x: availableSpace.x,
                          y: availableSpace.y
                        }
                      })
                    }
                  }}
                  disabled={!availableSpace}
                >
                  <ListItemIcon>
                    <Icon name="ContentCopyOutlined" />
                  </ListItemIcon>
                  <ListItemText primary="Duplicera" />
                </MenuItem>
              </Box>
            </Tooltip>
          ) : null}
          {editMode ? (
            <MenuItem
              onClick={() => {
                handleClose()

                setCopyToDashboardDialogOpen(true)
              }}
            >
              <ListItemIcon>
                <Icon name="DriveFileMoveOutlined" />
              </ListItemIcon>
              <ListItemText primary="Kopiera till" />
            </MenuItem>
          ) : null}

          {editMode ? <Divider /> : null}

          {editMode ? (
            <MenuItem
              onClick={() => {
                handleClose()
                setDeleteDialogOpen(true)
              }}
              key={2}
            >
              <ListItemIcon>
                <Icon name="DeleteOutlined" />
              </ListItemIcon>
              <ListItemText primary="Radera" />
            </MenuItem>
          ) : null}
        </Menu>
      </ThemeProvider>

      {/*-- dialogs --*/}
      <ThemeProvider theme={getTheme('light')}>
        <DeleteDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title={widget.title}
          handleDelete={() => {
            tryDeleteWidget(widget.id, widget.dashboard_id)

            setDeleteDialogOpen(false)
          }}
        />

        <EmbeddedDialog
          dashboardFilterOptions={dashboardFilterOptions}
          open={embeddedDialogOpen}
          onClose={() => setEmbeddedDialogOpen(false)}
          onChange={(embeddable) =>
            tryUpdateWidget(widget.id, { data: { embeddable } })
          }
          loading={loading}
          widget={widget}
        />

        <InformationDialog
          kpiTemplates={kpiTemplates}
          onClose={() => setInformationDialogOpen(false)}
          open={informationDialogOpen}
          widget={widget}
        />

        <CopyToDashboardDialog
          formattedData={formattedData}
          onClose={() => setCopyToDashboardDialogOpen(false)}
          open={copyToDashboardDialogOpen}
          widget={widget}
        />
      </ThemeProvider>
    </>
  )
}

export default connector(WidgetMenu)
