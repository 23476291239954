import { getWithRedirect } from 'utils/axios'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}measurements`

export async function getCustomers() {
  return getWithRedirect(`${URL}/mindset-funnel/customers`)
}

export async function getUsers() {
  return getWithRedirect(`${URL}/mindset-funnel/users`)
}

export async function getGraphData() {
  return getWithRedirect(`${URL}/mindset-funnel/graph-data`)
}

export async function getNSMData() {
  return getWithRedirect(`${URL}/nsm`)
}

export async function getApiLogs() {
  return getWithRedirect(`${URL}/api-logs`)
}

export async function getUsersUsage() {
  return getWithRedirect(`${URL}/usage/users`)
}

export async function getOneUserUsage(id: string) {
  return getWithRedirect(`${URL}/usage/users/${id}`)
}
