import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { IDashboardExample } from 'types/GlobalDashboardExample'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ApplicationState } from 'redux/Stores/types'
import * as DashboardExampleActions from 'redux/actions/DashboardExamples'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'

import LoadingSection from 'components_new/molecules/LoadingSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import DashboardExampleThumbnail from 'components_new/organisms/DashboardExampleThumbnail'
import DashboardTreeSelector from 'components_new/organisms/DashboardTreeSelector'

interface ActivateDashboardExampleDialogProps {
  dashboard: IDashboardExample
  onClose: () => void
  open: boolean
  sx?: object
}

const ActivateDashboardExampleDialog = (props: ComponentProps) => {
  const {
    dashboard,
    onClose,
    open,
    // redux store
    AuthStore,
    // redux actions
    tryCopyDashboardExample
  } = props

  const history = useHistory()

  const params = useParams<{ id: string }>()

  const [activating, setActivating] = useState<boolean>(false)
  const [folder, setFolder] = useState<null | string>(null)

  const handleActivate = () => {
    setActivating(true)

    // timeout for a "setup experience":
    setTimeout(() => {
      tryCopyDashboardExample(
        params.id,
        folder,
        dashboard.fields.title,
        (id: string | null, err?: boolean) => {
          if (id && !err) {
            handleCloseDialog()
            history.push(`/dashboards/${id}?created_from_example=${params.id}`)
          }
        }
      )
    }, 2500)
  }

  const handleCloseDialog = () => {
    onClose()
    setActivating(false)
    setFolder(null)
  }

  return (
    <>
      {/*-- menus --*/}
      <SimpleDialog
        actions={
          activating ? null : (
            <>
              <Button
                disabled={activating}
                onClick={handleCloseDialog}
                variant="text"
              >
                Avbryt
              </Button>
              <Button disabled={activating} onClick={handleActivate}>
                Aktivera
              </Button>
            </>
          )
        }
        contentText={
          activating
            ? undefined
            : 'Välj den mapp som du vill att din dashboard ska sparas i när den har aktiverats.'
        }
        maxWidth="xs"
        onClose={handleCloseDialog}
        open={open}
        title="Aktivera dashboard"
      >
        <Box
          sx={{
            position: 'relative'
          }}
        >
          {activating ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                bgcolor: 'rgba(255, 255, 255, 0.92)'
              }}
            >
              <LoadingSection
                loading={true}
                sx={{ py: 2 }}
                titles={['Sätter upp dashboarden', 'Anpassar data']}
              />
            </Box>
          ) : null}

          <DashboardExampleThumbnail
            createdAt={dashboard.sys.createdAt}
            disabled={true}
            id={dashboard.fields.id}
            key={dashboard.fields.id}
            href={`/explore/${dashboard.fields.id}`}
            title={dashboard.fields.title}
            updatedAt={dashboard.sys.updatedAt}
            user={AuthStore.user}
          />

          <Collapse in={!activating}>
            <DashboardTreeSelector selected={folder} setSelected={setFolder} />
          </Collapse>
        </Box>
      </SimpleDialog>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(DashboardExampleActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  ActivateDashboardExampleDialogProps

export default connector(ActivateDashboardExampleDialog)
