import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <Skeleton height={200} key={i} />
      ))}
    </>
  )
}

export default Loading
