import React, { FC, useState } from 'react'

import Box from 'components_new/atoms/Box'

import FixedSideButton from 'components_new/molecules/FixedSideButton'

import { useTheme } from '@mui/material'

interface CollapsibleSectionProps {
  children?: any
  defaultCollapsed?: boolean
  tooltipSuffix?: string
}

const CollapsibleSidebar: FC<CollapsibleSectionProps> = (
  props: CollapsibleSectionProps
) => {
  const { children, defaultCollapsed = false, tooltipSuffix = '' } = props
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed)
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          flex: '1 1 auto',
          minWidth: 0,
          height: '100%',
          position: 'relative'
        }}
      >
        <FixedSideButton
          iconName="FilterAlt"
          onClick={() => setCollapsed(!collapsed)}
          tooltip={`${
            collapsed ? 'Öppna' : 'Stäng'
          } ${tooltipSuffix.toLowerCase()}`}
        />
      </Box>
      <Box
        sx={{
          bgcolor: 'background.default',
          border: '1px solid',
          borderColor: 'divider',
          height: '100%',
          minWidth: collapsed ? 0 : 260,
          maxWidth: collapsed ? 0 : 500,
          flexBasis: collapsed ? undefined : '8%',
          position: 'relative',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflowY: 'auto'
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  )
}

export default CollapsibleSidebar
